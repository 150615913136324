import { Box, Button, Center, Flex, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'

const HomeContentDesktop = () => {
  return (
    <Box>
      <Header/>
    <Box color={'white'}>
        <Center><Text fontSize={250} >404</Text></Center>
        <Center><Text fontSize={50}>Ups</Text></Center>
        <Center><Text fontSize={50}>Halaman Tidak Ditemukan</Text></Center>
        <Center><Button mt={5} fontFamily={'arial'} fontWeight={'bold'} bg={'#FF99D9'}><Link to='/'>Ke Halaman Utama</Link></Button></Center>
        <Box height={28}/>
    </Box>
    <Box bg={'#D30E8A'} px={150} py={30}>
        <Box py={17}>
            <Image width={150} height={50} src='/images/gambar-logo.png'/>
        </Box>
        <Box>
            <Text color={'white'} fontFamily={'arial'} fontSize={20} >Belanja Kebutuhan LPJ cukup lewat satu Aplikasi</Text>
        </Box>
        <Flex py={55} experimental_spaceX={40}>
        <Box color={'white'} fontFamily={'arial'} fontSize={23}>
            <Text>Perusahaan</Text>
            <Text><Link to='/tentang'>Tentang kami</Link></Text>
            <Text><Link to='/layanan'>Layanan</Link></Text>
        </Box>
        <Box color={'white'} fontFamily={'arial'} fontSize={23}>
            <Text>Bantuan</Text>
            <Text><Link className='link1' to='/bantuan'>Pusat bantuan</Link></Text>
        </Box>
        <Box>
            <Box color='white' fontFamily='arial' fontSize='23px'>
                <Text >Kontak</Text>
                <Text>info@brightgasaja.com</Text>
                <Text>(0411) 422961</Text>
                <Text>Jl. A. P. Pettarani No. 18 G,</Text>
                <Text>Tamamaung, Kec. Pannakukang,</Text>
                <Text>Kota Makassar, Sulawesi Selatan</Text>
                <Text>90222</Text>
            </Box>
        </Box>
        </Flex>
        <Flex justifyContent={'space-between'}>
            <Text color='white' fontFamily='calibri' fontSize='20'>Copyright © 2022 Pt. Massindo Gas Energi</Text>
            <Flex experimental_spaceX={2} color={'white'} fontFamily={'calibri'} fontSize={20}><Text><Link className='b' to='/ketentuan'>Ketentuan Layanan</Link></Text><Text>|</Text><Text><Link className='b' to='/kebijakan'>Kebijakan Privasi</Link></Text></Flex>
        </Flex>
        </Box>
    </Box>
  )
}

const HomeMobileContent = () => {
  return (
    <Box>
      <Header/>
    <Box color={'white'}>
      <Box height={170}/>
        <Center><Text fontSize={100} >404</Text></Center>
        <Center><Text fontSize={20}>Ups</Text></Center>
        <Center><Text fontSize={20}>Halaman Tidak Ditemukan</Text></Center>
        <Center><Button mt={5} fontFamily={'arial'} fontWeight={'bold'} bg={'#FF99D9'}><Link to='/'>Ke Halaman Utama</Link></Button></Center>
      <Box height={235}/>
    </Box>
    <Box bg={'#D30E8A'} p={5}>
            <Box experimental_spaceY={2}>
                <Image width={110} height={10} src='/images/gambar-logo.png'/>
                <Text color={'white'} fontFamily={'arial'} fontSize={18} >Belanja Kebutuhan LPJ cukup lewat satu Aplikasi</Text>
            </Box>
            <Flex py={10} experimental_spaceX={20}>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <Text fontWeight={'bold'}>Perusahaan</Text>
                <SimpleGrid>
                    <Link className='link' to='/tentang'>Tentang kami</Link>
                    <Link className='link' to='/layanan'>Layanan</Link>
                </SimpleGrid>  
            </Box>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <SimpleGrid>
                    <Text fontWeight={'bold'}>Bantuan</Text>
                    <Link className='link' to='/bantuan'>Pusat bantuan</Link>
                </SimpleGrid>
            </Box>
            </Flex>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <Text fontWeight={'bold'}>Kontak</Text>
                <Text>info@brightgasaja.com</Text>
                <Text>(0411) 422961</Text>
                <Text>Jl. A. P. Pettarani No. 18 G,</Text>
                <Text>Tamamaung, Kec. Pannakukang,</Text>
                <Text>Kota Makassar, Sulawesi Selatan</Text>
                <Text>90222</Text>
            </Box>
            <Box py={5} experimental_spaceY={3}>
                <Text color={'white'} fontFamily={'arial'} fontSize={15}>Copyright © 2022 Pt. Massindo Gas Energi</Text>
                <Flex color={'white'} fontFamily={'calibri'} fontSize={17} experimental_spaceX={4}>
                    <Link className='b' to='/ketentuan'>Ketentuan Layanan</Link>
                    <Text color={'white'} fontFamily={'Calibri'} fontSize={15}>|</Text>
                    <Link className='b' to='/kebijakan'>Kebijakan Privasi</Link>
                </Flex>
            </Box>
        </Box>
    </Box>
  )
}

const Notfound = () => {
    const [isMobile] = useMediaQuery('(max-width: 1020px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};

export default Notfound
