import Header from "./Header";
import { Box, Center, Flex, Image , Text ,Tabs, TabList, TabPanels, Tab, TabPanel, Icon ,useMediaQuery } from '@chakra-ui/react'
import Footer from "./Footer";
import React from 'react';

const Cards = ({title, deskripsi, image}) => (
  <Box bg={'#E75CB4'} experimental_spaceY={10} rounded={'3xl'} py={10} px={5} color={'white'} width={'300px'} height={'600px'} >
      <Center><Image src={image} width={150} height={150} /></Center>
      <Box bg={'#F383CA'} rounded={'3xl'} fontWeight={'bold'} p={5}>
        <Text fontSize={'2xl'}>{title}</Text>
      </Box>
      
      <Box bg={'#F383CA'} rounded={'3xl'} p={5} >
        <Text>{deskripsi}</Text>
      </Box>
  </Box>
)

const HomeContentDesktop = () => {
      React.useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: "smooth"})
      },[])
      return (
      <Box>
      <Header/>
      <Box >
      <Box px={150} py={150}>
      <Box p={5} bg={'#FDA9DE'} rounded={'3xl'}>
      <Flex experimental_spaceX={2}>
        <Box bg={'#FDA9DE'} color={'#D30E8A'} py={10} px={5} rounded={'2xl'} experimental_spaceY={6}>
          <Box bg={'white'} rounded={'3xl'} fontWeight={'bold'} p={5}>
            <Text fontSize={'20'}>Subsidized LPJ Market</Text>
          </Box>
          <Box bg={'white'} rounded={'3xl'}p={5}>
            <Text fontSize={'20'}>Bright Gas adalah Aplikasi Subsidized LPJ Market yang hanya menampilkan Toko/Agen ataupun Outlet Penjualan Gas LPJ </Text>
          </Box>
          <Box bg={'white'} rounded={'3xl'} fontWeight={'bold'} p={5}>
            <Text fontSize={'20'}>#BelanjaLebih Cepat dan Mudah</Text>
          </Box>
        </Box>
          <Image src="/images/gambar-toko.png"/>
      </Flex>
      </Box>
      </Box>
      <Box bg={'white'} px={150} roundedTop={20}>
      <Flex justifyContent={'space-between'} py={20}>
        <Box>
          <Image width={600} height={500} src="/images/gambar-14.png" />
        </Box>
        <Box experimental_spaceY={3} fontFamily={'arial'} color={'black'}>
          <Box fontWeight={'bold'} fontSize={45}>
            <Text>Kenapa Harus Bright</Text>
            <Text>Gas?</Text>
          </Box>
          <Box fontSize={20}>
            <Text>Bright Gas dilengkapi dengan security seal cap atau</Text>
            <Text>penutup tabung yang lebih aman. karena penutup</Text>
            <Text>tabung tersebut menggunakan teknologi double spindle </Text>
            <Text>dan karet pelindung. Dengan adanya teknologi ini, </Text>
            <Text>tabung Bright Gas jadi lebih tahan benturan sehingga </Text>
            <Text>lebih aman untuk digunakan.</Text>
          </Box>
          </Box>
        </Flex>
        <Flex justifyContent={'space-between'} py={20}>
          <Box experimental_spaceY={3} fontFamily={'arial'} color={'black'}>
            <Box fontWeight={'bold'} fontSize={45}>
              <Text>Apa yang membuat</Text>
              <Text>Bright Gas berbeda?</Text>
            </Box>
            <Box fontSize={20}>
              <Text>Bright Gas hanya menampilkan produk penjualan Gas </Text>
              <Text>LPJ yang dikemas dalam Tabung dan Adapun kaleng </Text>
              <Text>sehingga sangat praktis dan mudah dibawa saat </Text>
              <Text>berpergian.</Text>
            </Box>
          </Box>
          <Box>
            <Image width={600} height={500} src="/images/gambar-15.png"/>
          </Box>
        </Flex>
        </Box>
        <Center mt={20}>
          <Text color={'white'} fontSize={50} fontFamily={'arial'} fontWeight={'bold'}>Apa Keunggulan Bright Gas?</Text>
        </Center>
        <Box py={16}>
        <Center>
          <Flex experimental_spaceX={28} py={10}>
            <Cards title={'Dilengkapi Security Seal Cap'} deskripsi={'Penutup tabung menggunakan teknologi double spindle dan karet pelindung. tabung Bright Gas jadi lebih tahan benturan sehingga lebih aman untuk digunakan.'} image={"/images/gambar-1.png"} />
            <Cards title={'Memiliki Katup Pengaman Ganda'} deskripsi={'Penutup tabung menggunakan teknologi double spindle dan karet pelindung. tabung Bright Gas jadi lebih tahan benturan sehingga lebih aman untuk digunakan.'} image={"/images/gambar-2.png"} />
            <Cards title={'Dilengkapi Safety Valve'} deskripsi={'Safety Valve berfungsi untuk mengeluarkan tekanan gas secara perlahan bila tekanan dalam tabung terlalu tinggi.'} image={"/images/gambar-3.png"} />
          </Flex>
        </Center>
        <Center>
        <Flex experimental_spaceX={28}>
          <Cards title={'Dilengkapi QR Code'} deskripsi={'Kode yang tertera di segel tabung Bright Gas ini dapat dipindai dengan aplikasi QR Code pada smartphone.'} image={"/images/gambar-4.png"} />
          <Cards title={'Dilengkapi Segel Hologram'} deskripsi={'Tabung Bright Gas sudah dilengkapi dengan segel hologram sehingga isinya akan lebih terjamin,hologram sebagai segel tabung.'} image={"/images/gambar-5.png"} />
        </Flex>
        </Center>
        </Box>
      </Box>
      <Footer/>
      </Box>
  )
}

const HomeMobileContent = () => {
  return (
    <Box>
      <Header/>
    <Box py={5}>
      <Box px={5} mb={10}>
      <Box bg={'#FDA9DE'} color={'#D30E8A'} py={10} px={5} rounded={'2xl'} experimental_spaceY={6}>
        <Box bg={'white'} rounded={'3xl'} fontWeight={'bold'} p={5}>
          <Text fontSize={'20'}>Subsidized LPJ Market</Text>
        </Box>
        <Box bg={'white'} rounded={'3xl'}p={5}>
          <Text fontSize={'20'}>Bright Gas adalah Aplikasi Subsidized LPJ Market yang hanya menampilkan Toko/Agen ataupun Outlet Penjualan Gas LPJ </Text>
        </Box>
        <Box bg={'white'} rounded={'3xl'} fontWeight={'bold'} p={5}>
          <Text fontSize={'20'}>#BelanjaLebih Cepat dan Mudah</Text>
        </Box>
      </Box>
      </Box>
      <Box px={5} bg={'white'} roundedTop={'3xl'}>
      <Box py={20} experimental_spaceY={5} color={'black'}>
        <Image src="/images/gambar-14.png"/>
        <Text fontSize={'2xl'} fontWeight={'bold'}>Kenapa Harus Bright Gas?</Text>
        <Text >Bright Gas dilengkapi dengan security seal cap atau penutup tabung yang lebih aman. karena penutup tabung tersebut menggunakan teknologi double spindle dan karet pelindung. Dengan adanya teknologi ini, tabung Bright Gas jadi lebih tahan benturan sehingga lebih aman untuk digunakan.</Text>
      </Box>
      <Box experimental_spaceY={5} color={'black'}>
        <Image src="/images/gambar-15.png"/>
        <Text fontSize={'2xl'} fontWeight={'bold'}>Apa yang membuat Bright Gas berbeda?</Text>
        <Text >Bright Gas hanya menampilkan produk penjualan Gas LPJ yang dikemas dalam Tabung dan Adapun kaleng sehingga sangat praktis dan mudah dibawa saat berpergian.</Text>
      </Box>
      <Box height={10}/>
      </Box>
      <Box py={20} color={'white'} >
        <Center><Text fontWeight={'extrabold'} fontSize={'22'}>Apa Keunggulan Bright Gas?</Text></Center>
        <Center><Tabs variant='solid-rounded' colorScheme={'gray'}>
        <TabPanels>
          <TabPanel defaultIndex={1}>
          <Cards title={'Dilengkapi Security Seal Cap'} deskripsi={'Penutup tabung menggunakan teknologi double spindle dan karet pelindung. tabung Bright Gas jadi lebih tahan benturan sehingga lebih aman untuk digunakan.'} image={"/images/gambar-1.png"} />
        </TabPanel>
          <TabPanel>
          <Cards title={'Memiliki Katup Pengaman Ganda'} deskripsi={'Penutup tabung menggunakan teknologi double spindle dan karet pelindung. tabung Bright Gas jadi lebih tahan benturan sehingga lebih aman untuk digunakan.'} image={"/images/gambar-2.png"} />
          </TabPanel>
          <TabPanel><Cards title={'Dilengkapi Safety Valve'} deskripsi={'Safety Valve berfungsi untuk mengeluarkan tekanan gas secara perlahan bila tekanan dalam tabung terlalu tinggi.'} image={"/images/gambar-3.png"} /></TabPanel>
          <TabPanel><Cards title={'Dilengkapi QR Code'} deskripsi={'Kode yang tertera di segel tabung Bright Gas ini dapat dipindai dengan aplikasi QR Code pada smartphone.'} image={"/images/gambar-4.png"} /></TabPanel>
          <TabPanel><Cards title={'Dilengkapi Segel Hologram'} deskripsi={'Tabung Bright Gas sudah dilengkapi dengan segel hologram sehingga isinya akan lebih terjamin,hologram sebagai segel tabung.'} image={"/images/gambar-5.png"} /></TabPanel>
          
        </TabPanels>
        <Center><TabList>
          <Tab><Icon viewBox='0 0 200 200' color='white'><path fill='currentColor' d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'/></Icon></Tab>
          <Tab><Icon viewBox='0 0 200 200' color='white'><path fill='currentColor' d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'/></Icon></Tab>
          <Tab><Icon viewBox='0 0 200 200' color='white'><path fill='currentColor' d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'/></Icon></Tab>
          <Tab><Icon viewBox='0 0 200 200' color='white'><path fill='currentColor' d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'/></Icon></Tab>
          <Tab><Icon viewBox='0 0 200 200' color='white'><path fill='currentColor' d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'/></Icon></Tab>
        </TabList></Center>
        </Tabs>
        </Center>
      </Box>
      <Box px={5}>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Belum Punya</Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Aplikasi Bright Gas? </Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Dapatkan Sekarang </Text>
        </Box>
        <Box px={5} experimental_spaceY={3} py={14}>
          <Text fontWeight={'bold'} color={'white'}>Unduh Aplikasi</Text>
          <Image width={130} src='/images/gambar-13.png' />
        </Box>
    </Box>
    <Box>
        <Center><Image src='/images/gambar-12.png' width={300}/></Center>
    </Box>
    <Footer/>
    </Box>
  )
}


export default function Tengtang(){
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  if(isMobile) return <HomeMobileContent/>

  else return <HomeContentDesktop/>
};