import Header from "./Header";
import { Text , Image , Box, useMediaQuery, Flex, Center } from '@chakra-ui/react'
import Footer from "./Footer";

const HomeContentDesktop = () => {
  return (
    <Box>
    <Header/>
    <Box px={150}>
    <Center mt={10}>
      <Text color='white' fontSize='50' fontFamily='arial' fontWeight='bold'>Anda Punya Pertanyaan?</Text>
    </Center>
    <Flex py={20} justifyContent={'space-between'}>
      <Box experimental_spaceY={4}>
        <Box fontFamily={'arial'} fontSize={30} fontWeight={'bold'} color={'white'}>
          <Text>PT. Massindo Gas Energi</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={'25'} color='white'>
          <Text>Jl. A. P. Pettarani No 18 G, </Text>
          <Text>Tamamaung, Kec, Panakkukang, </Text>
          <Text>Kota Makassar, Sulawesi Selatan </Text>
          <Text>90222 </Text>
        </Box>
      <Flex experimental_spaceX={2}>
        <Image height={37} src='/images/gambar-email.png'/>
        <Text fontFamily={'arial'} fontSize={25} color={'white'}>info@brihtgasaja.com </Text>
      </Flex>
      <Flex experimental_spaceX={2}>
        <Image height={37} src='/images/gambar-hp.png' />
        <Text fontFamily={'arial'} fontSize={25} color={'white'}>(0411) 422961 </Text>
      </Flex>
      </Box>
      <Box>
        <iframe width={'700'} height={'400'} id="gmap_canvas" src="https://maps.google.com/maps?q=Jl.%20A.%20P.%20Pettarani%20No.18%20G,%20Tamamaung,%20Kec.%20Panakkukang,%20Kota%20Makassar,%20Sulawesi%20Selatan%2090222&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </Box>
    </Flex>
    </Box>
    <Footer/>
    </Box>
  )
}

const HomeMobileContent = () => {
  return (
      <Box>
        <Header/>
        <Box px={5} py={5}>
          <Box color={'white'} fontWeight={'bold'} fontSize={26}>
          <Text>Anda Punya Pertanyaan?</Text>
          </Box>
          <Flex>
            <Box>
              <Box color={'White'} py={10}>
                <Text fontSize={20} fontWeight={'bold'}>PT. Massindo Gas Energi</Text>
                <Text fontSize={15}>Jl. A. P. Pettarani No.18 G, </Text>
                <Text>Tamamaung, Kec. Panakkukang, </Text>
                <Text>Kota Makassar, Sulawesi Selatan </Text>
                <Text>90222</Text>
                <Box py={3} experimental_spaceY={3}>
                  <Flex experimental_spaceX={2}>
                    <Image height={25} src="/images/gambar-email.png"/>
                    <Text>info@Brihtgasaja</Text>
                  </Flex>
                  <Flex experimental_spaceX={2}>
                    <Image height={25} src="/images/gambar-hp.png"/>
                    <Text>(0411)422961</Text>
                  </Flex>
                </Box>
                <Box py={7}>
                <iframe width={'100%'} height={'100%'} id="gmap_canvas" src="https://maps.google.com/maps?q=Jl.%20A.%20P.%20Pettarani%20No.18%20G,%20Tamamaung,%20Kec.%20Panakkukang,%20Kota%20Makassar,%20Sulawesi%20Selatan%2090222&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                </Box>
              </Box>
            </Box>
          </Flex>
            <Box>
              <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Belum Punya</Text>
              <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Aplikasi Bright Gas? </Text>
             <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Dapatkan Sekarang </Text>
            </Box>
              <Box experimental_spaceY={3} py={14}>
                <Text fontWeight={'bold'} color={'white'}>Unduh Aplikasi</Text>
                <Image width={130} src='/images/gambar-13.png' />
              </Box>
            </Box>
          <Box>
        <Center><Image src='/images/gambar-12.png' width={300}/></Center>
      </Box>
      <Footer/>
      </Box>
  )
}

export default function Kontak(){
    const [isMobile] = useMediaQuery('(max-width: 600px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};