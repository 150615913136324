import React from 'react';
import {Link,NavLink} from 'react-router-dom'
import { useMediaQuery,Box, useDisclosure,Button, Flex , Image, SimpleGrid, } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons';
  

  function Bar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
  
    return (
      <>
        <Button ref={btnRef} variant='ghost' onClick={onOpen}>
            <HamburgerIcon color={'white'}/>
        </Button>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton size={'lg'} />
            <DrawerBody>
                <Box>
                <Box height={10}/>
                <SimpleGrid fontWeight={'bold'} fontSize={15} experimental_spaceY={10}>
                <Link to='/'>Beranda</Link>
                <Link to='/tentang'>Tentang kami</Link>
                <Link to='/layanan'>Layanan</Link>
                <Link to='/kontak'>Kontak</Link>
                <Link to='/bantuan'>Bantuan</Link>
                </SimpleGrid>
                </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

const HomeContentDesktop = () => {
    return (
      <Box bg={'white'}>
        <Flex px={150} justifyContent={'space-between'}>
            <Box width={150} marginTop={3}>
            <Link to='/'><Image width={28} src='/images/gambar-logo-1.png'/></Link>
            </Box>  
            <Flex color={'white'} marginTop={1}>
                <Box experimental_spaceX={10} margin={5}>
                <NavLink to='/' style={({ isActive }) => (isActive ? {color: '#D30E8A'} : {color: 'grey'})}>Beranda</NavLink>
                <NavLink to='/tentang' style={({ isActive }) => (isActive ? {color: '#D30E8A'} : {color: 'grey'})}>Tentang kami</NavLink>
                <NavLink to='/layanan' style={({ isActive }) => (isActive ? {color: '#D30E8A'} : {color: 'grey'})}>Layanan</NavLink>
                <NavLink to='/kontak' style={({ isActive }) => (isActive ? {color: '#D30E8A'} : {color: 'grey'})}>Kontak</NavLink>
                <NavLink to='/bantuan' style={({ isActive }) => (isActive ? {color: '#D30E8A'} : {color: 'grey'})}>Bantuan</NavLink>
                </Box>
            </Flex>
        </Flex>
      </Box>
    )
}

const HomeMobileContent = () => {
    return (
        <Box>
            <Flex py={3} px={5} justifyContent={'space-between'}>
                <Image width={100} height={10} src='/images/gambar-logo.png'/>
                <Bar/>
            </Flex>
        </Box>
    )
}

const Header = () => {
    const [isMobile] = useMediaQuery('(max-width: 1020px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};

export default Header;