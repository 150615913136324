import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layanan from './components/Layanan';
import Kontak from './components/Kontak';
import Bantuan from './components/Bantuan';
import { ChakraProvider } from '@chakra-ui/react'
import Ketentuan from './components/Ketentuan';
import Kebijakan from './components/Kebijakan';
import Home from './components/Home';
import Tentang from './components/TentangKami';
import Notfound from './components/Notfound';

function App() {
  return (
  <ChakraProvider>
  <Router>
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='tentang' element={<Tentang/>}/>
        <Route path='layanan' element={<Layanan/>}/>
        <Route path='kontak' element={<Kontak/>}/>
        <Route path='bantuan' element={<Bantuan/>}/>
        <Route path='ketentuan' element={<Ketentuan/>}/>
        <Route path='kebijakan' element={<Kebijakan/>}/>
        <Route path='*' element={<Notfound/>}/>
    </Routes>
  </Router>
  </ChakraProvider>
  );
}
export default App;