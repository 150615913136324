import React from 'react';
import Header from './Header';
import { Box, Flex, SimpleGrid, Text, Image , useMediaQuery } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

const HomeContentDesktop = () => {
    React.useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: "smooth"})
      },[])
    return (
        <Box>
        <Header/>
        <Box bg={'white'}>
        <Box height='61 '>.</Box>
        <Box px={150} fontFamily={'arial'} fontSize={15}>
        <Text fontFamily='arial' fontSize='45' fontWeight='bold' >Kebijakan Privasi aplikasi Bright Gas</Text>
        <Text marginTop='5'>1. INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
        <Text marginTop='5'>Kami mengumpulkan informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi, menghubungi, atau menemukan 
        orang atau perangkat yang terkait dengan informasi tersebut ("Informasi Pribadi"). Informasi Pribadi termasuk, tetapi tidak terbatas pada,
        nama, alamat, tanggal lahir, pekerjaan, nomor telepon, nomor faks, alamat e-mail, rekening bank dan detail kartu kredit, jenis kelamin,
        identifikasi (termasuk KTP, SIM, atau Paspor) atau tanda pengenal lainnya yang dikeluarkan oleh pemerintah, foto, kewarganegaraan, 
        nomor telepon pengguna dan non-pengguna Aplikasi kami yang terdapat dalam daftar kontak telefon selular anda, serta informasi 
        biometrik (termasuk namun tidak terbatas pengenalan wajah). Selain itu, untuk informasi lainnya, seperti profil pribadi, dan/atau nomor 
        pengenal unik, yang dikaitkan atau digabungkan dengan Informasi Pribadi, maka informasi tersebut juga dianggap sebagai Informasi 
        Pribadi. Informasi Pribadi yang kami kumpulkan dapat diberikan oleh anda secara langsung atau oleh pihak ketiga(misalnya: ketika anda 
        mendaftar atau menggunakan Aplikasi, ketika anda menghubungi layanan pelanggan kami, atau sebaliknya ketika anda memberikan 
        Informasi Pribadi kepada kami). Kami dapat mengumpulkan informasi dalam berbagai macam bentuk dan tujuan (termasuk tujuan yang 
        diizinkan berdasarkan peraturan perundang-undangan yang berlaku.
        <Text  marginTop='5'>- Informasi yang kami  kumpulkan dari anda atau perangkat seluler anda secara langsung</Text>
        <Text  marginTop='5'>Ketika anda mendaftar dan membuat akun dengan kami menggunakan Aplikasi, anda harus memberikan kepada kami Informasi 
        Pribadi tertentu, termasuk nama, alamat fisik, alamat e-mail, nomor telepon anda, dan/atau kata sandi yang akan anda gunakan untuk 
        mengakses Aplikasi setelah pendaftaran. Jika anda menggunakan Aplikasi sebagai penyedia layanan, anda harus memberikan Informasi 
        Pribadi tambahan kepada kami sebagai bagian dari proses pendaftaran penyedia layanan. Ini akan mencakup rincian mengenai 
        kendaraan anda (izin, persetujuan, dan otorisasi lainnya yang anda perlukan untuk mengoperasikan kendaraan dan untuk menyediakan 
        layanan transportasi), polis asuransi anda, dan rekening bank anda untuk keperluan menerima pembayaran.</Text>
        <Text  marginTop='5'>- Ketika anda menggunakan Aplikasi, anda harus memberikan kami informasi yang relevan yang mungkin diperlukan oleh kami agar
        Aplikasi dapat berfungsi, contohnya:
        </Text>
        <Text  marginTop='5'></Text>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda menggunakan Aplikasi sebagai pengguna, anda harus memberikan informasi pada kami sesuai dengan jenis layanan yang 
         anda cari, dan rincian penjemputan dan/atau pengiriman.</Text>
        </Flex>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda menggunakan Aplikasi sebagai penyedia layanan, agar Aplikasi dapat berfungsi, anda harus memberikan kepada kami 
         informasi terkait dengan layanan yang anda dapat terima pesanannya pada saat itu, dan perinciannya pada saat ini, dan setelah 
         pesanan untuk layanan yang ditempatkan oleh pengguna diterima oleh anda sebagai penyedia layanan, anda mungkin akan perlu 
         memberikan kepada kami data lain yang kami perlukan untuk mengelola Aplikasi terkait, serta untuk memantau keseluruhan 
         penggunaan Aplikasi.</Text>
        </Flex>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet 
         elektronik yang disediakan oleh kami, jika anda adalah pihak pembayar, anda akan menyediakan informasi kepada kami yang 
         berkaitan dengan penggunaan, pembayaran atau transfer, termasuk tetapi tidak terbatas pada rincian transfer dan/atau penerima 
         pembayaran, jumlah pembayaran yang dibayarkan, jenis kartu pembayaran yang digunakan, nama penerbit kartu pembayaran 
         tersebut, nama pemegang rekening dan nomor kartu pembayaran tersebut.</Text>
        </Flex>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda bermaksud untuk mendaftar sebagai pemegang akun terdaftar atau terverifikasi dari fasilitas uang elektronik yang disediak
         an oleh kami, anda akan memberikan informasi kepada kami yang diperlukan , tetapi tidak terbatas pada nama lengkap anda, nomor
         kartu identitas anda, jenis kartu identitas yang anda gunakan untuk registrasi, alamat, jenis kelamin, tempat dan tanggal lahir, 
         pekerjaan, kebangsaan dan/atau data biometrik. </Text>
        </Flex>
        <Text  marginTop='5'>Informasi yang dikumpulkan setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami</Text>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami,kami dapat mengumpulkan data teknis tertentu sehubungan 
        dengan penggunaan anda seperti, alamat protokol internet, informasi halaman web yang sebelumnya atau selanjutnya dilihat, durasi 
        setiap kunjungan/sesi, identitas perangkat internet atau alamat kontrol akses media, dan informasi mengenai produsen, model, dan 
        sistem operasi dari perangkat yang anda gunakan untuk mengakses Aplikasi atau situs web kami.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Ketika anda menggunakan Aplikasi atau mengunjungi situs web kami, informasi tertentu juga dapat dikumpulkan secara otomatis 
        dengan menggunakan cookies. Cookies adalah berkas data kecil yang tersimpan pada komputer atau perangkat seluler anda. Kami 
        menggunakan cookies untuk melacak aktivitas pengguna dengan tujuan untuk meningkatkan antarmuka dan pengalaman pengguna. 
        Sebagian besar perangkat seluler dan peramban internet mendukung penggunaan cookies; namun anda dapat menyesuaikan 
        pengaturan pada perangkat seluler atau peramban internet anda untuk menolak beberapa jenis cookies atau cookies spesifik tertentu. 
        Perangkat seluler dan/atau peramban anda juga memungkinkan anda untuk menghapus cookies apa pun yang sebelumnya telah 
        tersimpan. Namun demikian, hal itu dapat mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi atau situs web kami.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Setiap kali anda menggunakan Aplikasi melalui perangkat seluler, kami akan melacak dan mengumpulkan informasi lokasi geografis 
        anda dalam waktu sebenarnya. Dalam beberapa kasus, anda akan diminta atau diharuskan untuk mengaktifkan Global Positioning 
        System (GPS) pada perangkat seluler anda untuk memungkinkan kami agar dapat memberikan anda pengalaman yang lebih baik 
        dalam menggunakan Aplikasi (misalnya, untuk memberikan informasi tentang seberapa dekat penyedia layanan dengan anda). Anda 
        dapat menonaktifkan informasi pelacakan lokasi geografis pada perangkat seluler anda untuk sementara waktu. Namun, hal ini dapat 
        mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Jika anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet 
        elektronik yang disediakan oleh kami, kami dapat mengumpulkan informasi tertentu terkait dengan sumber dana yang anda gunakan 
        untuk melakukan isi ulang (termasuk rincian rekening bank), rincian rekening penerima penarikan, riwayat transaksi (termasuk detail 
        penerima), rincian tagihan, faktur, dan nomor telepon.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Jika anda menggunakan akun virtual yang disediakan oleh kami untuk menerima pembayaran menggunakan uang elektronik dan
        /atau dompet elektronik dari pembayar, baik anda penyedia layanan atau merchant, kami dapat mengumpulkan informasi tertentu 
        yang terkait dengan penggunaan anda termasuk tetapi tidak terbatas pada layanan dan/atau barang yang ditransaksikan, jumlah
        yang anda kumpulkan dari setiap transaksi, rincian akun penarikan atau penyelesaian pembayaran dan riwayat penarikan atau 
        penyelesaian pembayaran.</Text>
        </Flex>
        <Text  marginTop='5'>Informasi tentang pihak ketiga yang anda berikan kepada kami</Text>
        <Flex mt={2}>
        <Text >a.</Text>
        <Text >Anda dapat memberikan kepada kami Informasi Pribadi yang berkaitan dengan individu pihak ketiga lainnya (termasuk Informasi 
         Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau individu lain). Anda tentu saja akan memerlukan 
         persetujuan dari mereka terlebih dahulu untuk melakukannya lihat “Pengakuan dan Persetujuan”, di bawah, untuk informasi lebih 
         lanjut.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >b.</Text>
        <Text >Kami tidak mewajibkan atau mendukung, ataupun melarang, pemasangan atau penggunaan alat perekam dalam kendaraan di 
         kendaraan apa pun. Jika perangkat tersebut dipasang dan digunakan di kendaraan, kami tidak mengumpulkan Informasi Pribadi 
         apapun dari rekaman dalam kendaraan oleh perangkat tersebut, dan pengumpulan Informasi Pribadi dari rekaman di dalam 
         kendaraan tersebut tidak dilakukan atas nama kami. Pengumpulan Informasi Pribadi dari rekaman di dalam kendaraan tersebut 
         merupakan diskresi dari {<br/>}
         penyedia layanan. Kami tidak memiliki kendali atas pengumpulan Informasi Pribadi tersebut oleh penyedia layanan dan setiap 
         penggunaan atau pengungkapan oleh penyedia layanan dari Informasi Pribadi yang dikumpulkan.Jika anda adalah penyedia layanan 
         dan memilih untuk mengunduh dan menggunakan perangkat rekaman di dalam kendaraan tersebut di kendaraan anda, anda 
         bertanggung jawab sepenuhnya untuk memberitahukan pengguna. Jika anda adalah seorang pengguna dan anda keberatan atas 
         penggunaan alat perekam dalam kendaraan penyedia layanan, anda harus menyampaikan hal tersebut kepada penyedia layanan 
         secara langsung dan hal tersebut menjadi tanggung jawab pribadi anda</Text>
        </Flex>
        <Flex mt={2}>
        <Text >c.</Text>
        <Text >Jika anda berkeinginan untuk menggunakan fitur pesan di Aplikasi kami, anda akan memberikan nomor telefon pengguna kami yang 
         tersimpan di kontak telefon selular anda secara rutin untuk membantu anda dalam menggunakan fitur pesan kami serta tujuan 
         internal lainnya.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>2. PENGGUNAAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
        <Text marginTop='5'>Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan sebagai berikut maupun untuk tujuan lain yang diizinkan 
        oleh peraturan perundang-undangan yang berlaku ("Tujuan"):
        <Text  marginTop='5'> Jika anda adalah pengguna, kami dapat menggunakan Informasi Pribadi anda: </Text>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk mengidentifikasi dan mendaftarkan anda sebagai pengguna dan untuk mengadministrasikan, memverifikasi, menonaktifkan, 
        atau mengelola akun anda;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan kami diperlukan sebelum penyedia layanan 
        memberikan layanan kepada anda atau sebelum kami mendaftarkan anda sebagai pengguna, termasuk proses Mengenal Pelanggan 
        (Know Your Customer).</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memungkinkan penyedia layanan untuk menyediakan layanan yang anda minta.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk mengolah dan memfasilitasi pesanan dan transaksi pembayaran yang anda lakukan.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan 
        preferensi anda sebagai pengguna</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan penggunaan berbagai 
        layanan yang tersedia di Aplikasi</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra kami dan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk mengirimkan anda komunikasi pemasaran, iklan, voucher, dan informasi secara langsung atau terfokus, dan informasi tentang 
        penawaran atau promosi khusus.</Text>
        </Flex>
        <Text  marginTop='5'>Jika anda adalah penyedia layanan, kami dapat menggunakan Informasi Pribadi anda</Text>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk mengidentifikasi anda dan mendaftarkan anda sebagai penyedia layanan dan untuk mengadministrasi, mengelola atau 
        memverifikasi Akun anda</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan kami mungkin perlu dilakukan sebelum kami 
        dapat mendaftarkan anda sebagai penyedia layanan.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memungkinkan anda memberikan layanan kepada pengguna</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran kepada anda terkait dengan layanan yang telah anda berikan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk berkomunikasi dengan anda dan mengirimkan anda informasi terkait dengan penyediaan layanan anda, termasuk untuk 
        menyampaikan pesanan pengguna kepada anda dan untuk memfasilitasi penerimaan anda atas pesanan tersebut</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memberikan anda pemberitahuan dan pembaruan pada Aplikasi atau perubahan cara penyediaan layanan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memberi anda laporan terkait dengan layanan yang telah anda sediakan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memproses dan menanggapi saran dari pengguna terhadap layanan yang telah anda berikan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan 
        preferensi anda sebagai penyedia layanan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan daya tanggap 
        penyedia layanan untuk berbagai layanan yang tersedia pada Aplikasi</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memproses dan mengelola reward points anda</Text>
        </Flex>
        <Text  marginTop='5'>Baik anda merupakan pengguna ataupun penyedia layanan atau pihak yang memberikan Informasi Pribadi kepada kami, kami juga 
        dapat menggunakan Informasi Pribadi anda secara lebih umum untuk tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu 
        kami akan bertindak secara wajar dan tidak menggunakan Informasi Pribadi anda lebih dari apa yang diperlukan untuk tujuan tersebut)</Text>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk melakukan proses dan fungsi bisnis terkait;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' > untuk memantau penggunaan Aplikasi dan mengelola, mendukung serta meningkatkan efisiensi kinerja, perkembangan, pengalaman 
        pengguna dan fungsi-fungsi Aplikasi;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memberikan bantuan sehubungan dengan dan untuk menyelesaikan kesulitan teknis atau masalah operasional dengan Aplikasi 
        atau layanan;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk menghasilkan informasi statistik dan data analitik untuk tujuan pengujian, penelitian, analisis, pengembangan produk, kemitraan 
        komersial, dan kerja sama;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk mencegah, mendeteksi dan menyelidiki segala kegiatan yang dilarang, ilegal, tidak sah, atau curang;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memfasilitasi transaksi aset bisnis (yang dapat berupa penggabungan, akuisisi, atau penjualan aset) yang melibatkan kami dan
        /atau afiliasi kami; dan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memungkinkan kami mematuhi semua kewajiban berdasarkan peraturan perundang-undangan yang berlaku, (namun tidak 
        terbatas pada menanggapi permintaan, penyelidikan, atau arahan peraturan) dan melakukan pemeriksaan audit, uji tuntas dan 
        penyelidikan.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>3.PEMBERIAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
        <Text marginTop='5'>Kami dapat mengungkapkan atau membagikan Informasi Pribadi anda dengan afiliasi dan pihak lain untuk tujuan sebagai berikut ini 
        serta untuk tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku:
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >jika anda adalah pengguna, untuk memungkinkan penyedia layanan, untuk melakukan atau memberikan layanan;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >jika disyaratkan atau diotorisasikan oleh peraturan perundang-undangan yang berlaku (termasuk namun tidak terbatas pada 
        menanggapi pertanyaan terkait regulasi, penyelidikan atau pedoman, atau mematuhi persyaratan atau ketentuan pengarsipan dan 
        pelaporan berdasarkan undang-undang), untuk tujuan yang ditentukan dalam peraturan perundang-undangan yang berlaku</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >jika terdapat proses hukum dalam bentuk apapun antara anda dengan kami, atau antara anda dengan pihak lain, sehubungan 
        dengan, atau terkait dengan layanan, untuk keperluan proses hukum tersebut</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >sehubungan dengan segala proses verifikasi yang menurut kami atau penyedia layanan perlu sebelum kami mendaftarkan anda
        sebagai pengguna, termasuk proses Mengenal Pelanggan (Know Your Customer)</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >dalam keadaan darurat terkait kesehatan dan/atau keselamatan anda (baik anda adalah pengguna atau penyedia layanan) untuk 
        keperluan menangani keadaan darurat tersebut</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >sehubungan dengan, penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi semua 
        atau sebagian dari bisnis kami oleh atau ke perusahaan lain, untuk keperluan transaksi tersebut (bahkan jika kemudian transaksi
        tidak dilanjutkan)</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >sehubungan dengan proses klaim asuransi, kami akan membagikan Informasi Pribadi anda untuk tujuan pemrosesan klaim asuransi 
        kepada perusahaan asuransi yang bekerja sama atau berkolaborasi dengan kami</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >kepada pihak ketiga  yang memberikan layanan kepada kami atau anda, melakukan tugas atas nama kami, atau pihak dengan siapa 
        kami mengadakan kerja sama komersial), untuk atau sehubungan dengan tujuan di mana pihak ketiga tersebut terlibat atau tujuan 
        kerja sama kami dengan pihak ketiga tersebut (tergantung keadaannya), yang dapat mencakup diperbolehkannya pihak ketiga 
        tersebut untuk memperkenalkan atau menawarkan produk atau layanan kepada anda, melakukan autentikasi terhadap anda atau 
        menghubungkan anda dengan Akun atau melakukan kegiatan lain termasuk pemasaran,penelitian, nalisis dan pengembangan produk.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >dalam hal kami berbagi Informasi Pribadi dengan afiliasi, kami akan melakukannya dengan maksud agar mereka membantu kami 
        dalam menyediakan Aplikasi, untuk mengoperasikan bisnis kami (termasuk, ketika anda berlangganan milis kami, untuk tujuan 
        pemasaran langsung), atau untuk tujuan pengolahan data atas nama kami.  Misalnya, sebuah afiliasi BRIGHT GAS di negara lain 
        dapat mengolah dan/atau menyimpan Informasi Pribadi anda atas nama perusahaan grup BRIGHT GAS di negara anda. 
        Semua afiliasi kami berkomitmen untuk mengolah Informasi Pribadi yang mereka terima dari kami sesuai dengan Kebijakan Privasi 
        dan peraturan perundang-undangan yang berlaku ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Ketika Informasi Pribadi tidak perlu dikaitkan dengan anda, kami akan melakukan upaya yang wajar untuk menghapus dikaitkannya 
        Informasi Pribadi tersebut dengan anda sebagai individu sebelum mengungkapkan atau berbagi informasi tersebut.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Kami tidak akan menjual atau menyewakan Informasi Pribadi anda.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami dapat mengungkapkan dan membagikan Informasi Pribadi anda jika 
        kami memberitahu anda dan kami telah memperoleh persetujuan anda untuk pengungkapan atau pembagian tersebut.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>4.PENYIMPANAN INFORMASI PRIBADI</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Informasi Pribadi anda hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, atau selama
        penyimpanan tersebut diperlukan atau diperbolehkan oleh peraturan perundang-undangan yang berlaku. Kami akan berhenti 
        menyimpan Informasi Pribadi, atau menghapus maksud dari dikaitkannya Informasi Pribadi tersebut dengan anda sebagai individu,
        segera setelah dianggap bahwa tujuan pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan dengan menyimpan Informasi 
        Pribadi dan penyimpanan tidak lagi diperlukan untuk tujuan bisnis atau secara hukum.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Informasi Pribadi anda disimpan oleh pihak lain (termasuk 
        oleh penyedia layanan, jika anda adalah pengguna; atau oleh pengguna, jika anda adalah penyedia layanan) dengan cara tertentu. 
        Informasi yang disampaikan melalui komunikasi antara pengguna dan penyedia layanan yang dilakukan selain melalui penggunaan
        Aplikasi (seperti melalui panggilan telepon, SMS, pesan seluler atau cara komunikasi lainnya) juga dapat disimpan dengan beberapa 
        cara. Kami tidak mengizinkan dan mendorong penyimpanan Informasi Pribadi dengan cara demikian dan kami tidak bertanggung 
        jawab kepada anda untuk hal tersebut. Kami tidak akan bertanggung jawab atas penyimpanan Informasi Pribadi anda. Anda setuju 
        untuk mengganti rugi, membela, dan membebaskan kami, pejabat, direktur, karyawan, agen, mitra, pemasok, kontraktor, dan afiliasi 
        kami dari dan terhadap setiap dan segala klaim, kerugian, kewajiban, biaya, kerusakan, dan ongkos (termasuk tetapi tidak terbatas 
        pada biaya hukum dan pengeluaran biaya ganti rugi penuh) yang dihasilkan secara langsung atau tidak langsung dari setiap 
        penyimpanan yang tidak sah atas Informasi Pribadi anda.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>5.AKSES DAN KOREKSI INFORMASI PRIBADI</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Tunduk pada peraturan perundang-undangan yang berlaku, anda dapat meminta kepada kami untuk mengakses dan/atau mengoreksi
        Informasi Pribadi anda yang berada dalam kepemilikan dan penguasaan kami, dengan menghubungi kami di perincian yang 
        disediakan di bawah ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Kami berhak menolak permintaan anda untuk mengakses, atau untuk memperbaiki, sebagian atau semua Informasi Pribadi anda 
        yang kami miliki atau kuasai jika diizinkan atau diperlukan berdasarkan perundang-undangan yang berlaku. Hal ini termasuk dalam 
        keadaan di mana Informasi Pribadi tersebut dapat berisi referensi kepada orang lain atau di mana permintaan untuk akses atau 
        permintaan untuk mengoreksi adalah untuk alasan yang kami anggap tidak relevan, tidak serius, atau menyulitkan.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>6.TEMPAT KAMI MENYIMPAN INFORMASI PRIBADI ANDA</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Informasi Pribadi dari anda yang kami kumpulkan dapat disimpan, ditransfer, atau diolah oleh penyedia layanan pihak ketiga. 
        Kami akan menggunakan semua upaya yang wajar untuk memastikan bahwa semua penyedia layanan pihak ketiga tersebut 
        memberikan tingkat perlindungan yang sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Informasi Pribadi anda juga dapat disimpan atau diproses di luar negara anda oleh pihak yang bekerja untuk kami di negara lain,
        atau oleh penyedia layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi kami. Dalam hal tersebut, kami akan 
        memastikan bahwa Informasi Pribadi tersebut tetap menjadi subjek dari tingkat perlindungan yang sebanding dengan apa yang 
        disyaratkan dalam hukum negara anda (dan, dalam hal apapun, sejalan dengan komitmen kami dalam Kebijakan Privasi ini).</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>7.KEAMANAN INFORMASI PRIBADI ANDA</Text>
        <Text marginTop='5'>Kerahasiaan Informasi Pribadi anda adalah hal yang terpenting bagi kami. Kami akan memberlakukan upaya terbaik untuk melindungi 
        dan mengamankan data dan Informasi Pribadi anda dari akses, pengumpulan, penggunaan atau pengungkapan oleh orang-orang 
        yang tidak berwenang dan dari pengolahan yang bertentangan dengan hukum, kehilangan yang tidak disengaja, pemusnahan dan 
        kerusakan atau risiko serupa. Namun, pengiriman informasi melalui internet tidak sepenuhnya aman. Walau kami akan berusaha 
        sebaik mungkin untuk melindungi Informasi Pribadi anda, anda mengakui bahwa kami tidak dapat menjamin keutuhan dan keakuratan 
        Informasi Pribadi apa pun yang anda kirimkan melalui Internet, atau menjamin bahwa Informasi Pribadi tersebut tidak akan dicegat, 
        diakses, diungkapkan, diubah atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor di luar kendali kami. 
        Anda bertanggung jawab untuk menjaga kerahasiaan detail Akun anda, termasuk kata sandi anda dan One Time Password (OTP) 
        dengan siapapun dan harus selalu menjaga dan bertanggung jawab atas keamanan perangkat yang anda gunakan.</Text>
        <Text marginTop='5'>8.PERUBAHAN ATAS KEBIJAKAN PRIVASI INI</Text>
        <Text marginTop='5'>Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan kami sendiri dari waktu ke waktu, untuk memastikan bahwa 
        Kebijakan Privasi ini konsisten dengan perkembangan kami di masa depan, dan/atau perubahan persyaratan hukum atau peraturan. 
        Jika kami memutuskan untuk mengubah Kebijakan Privasi ini, kami akan memberitahu anda tentang perubahan tersebut melalui 
        pemberitahuan umum yang dipublikasikan pada Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail anda yang tercantum 
        dalam Akun anda. Anda setuju bahwa anda bertanggung jawab untuk meninjau Kebijakan Privasi ini secara teratur untuk informasi
        terbaru tentang pengolahan data dan praktik perlindungan data kami, dan bahwa dengan anda terus menggunakan Aplikasi atau situs 
        web kami, berkomunikasi dengan kami, atau mengakses dan menggunakan layanan setelah adanya perubahan apapun terhadap 
        Kebijakan Privasi ini akan dianggap sebagai persetujuan anda terhadap Kebijakan Privasi ini dan segala perubahannya.
        </Text>
        <Text marginTop='5'>9.PENGAKUAN DAN PERSETUJUAN</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Dengan menyetujui Kebijakan Privasi, anda mengakui bahwa anda telah membaca dan memahami Kebijakan Privasi ini dan 
        menyetujui segala ketentuannya. Secara khusus, anda setuju dan memberikan persetujuan kepada kami untuk mengumpulkan,
        menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah Informasi Pribadi anda sesuai dengan 
        Kebijakan Privasi ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Dalam keadaan di mana anda memberikan kepada kami Informasi Pribadi yang berkaitan dengan individu lain (seperti Informasi 
        Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau pihak lain), anda menyatakan dan menjamin bahwa 
        anda telah memperoleh persetujuan dari individu tersebut untuk, dan dengan ini menyetujui atas nama individu tersebut untuk, 
        pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi Pribadi mereka oleh kami.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Anda dapat menarik persetujuan anda untuk setiap atau segala pengumpulan, penggunaan atau pengungkapan Informasi Pribadi 
        anda kapan saja dengan memberikan kepada kami pemberitahuan yang wajar secara tertulis menggunakan rincian kontak yang 
        disebutkan di bawah ini. Anda juga dapat menarik persetujuan pengiriman komunikasi dan informasi tertentu dari kami melalui fasilitas
        "opt-out" atau pilihan "berhenti berlangganan" yang tersedia dalam pesan kami kepada anda. Tergantung pada keadaan dan sifat 
        persetujuan yang anda tarik, anda harus memahami dan mengakui bahwa setelah penarikan persetujuan tersebut, anda mungkin
        tidak lagi dapat menggunakan Aplikasi atau layanan. Penarikan persetujuan anda dapat mengakibatkan penghentian Akun anda atau 
        hubungan kontraktual anda dengan kami, dengan semua hak dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah 
        menerima pemberitahuan untuk menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan Informasi Pribadi anda, 
        kami akan menginformasikan anda tentang konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga anda dapat 
        memutuskan apakah anda tetap ingin menarik persetujuan.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>10.DATA ANONIM</Text>
        <Text marginTop='5'>Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan Informasi Pribadi, yang tersedia, dengan catatan, (i) bahwa 
        semua hal yang dapat mengidentifikasi telah dihapus sehingga data, baik sendiri atau dikombinasi dengan data lain yang tersedia, 
        tidak dapat dihubungkan dengan atau dikaitkan dengan atau tidak dapat mengidentifikasi suatu individu, dan (ii) data serupa telah 
        digabungkan sehingga data asli membentuk bagian dari kumpulan data yang lebih besar.</Text>
        <Text marginTop='5'>11.PLATFORM PIHAK KETIGA</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Aplikasi, situs web, dan Materi Pemasaran dapat berisi tautan ke situs web yang dioperasikan oleh pihak ketiga. Kami tidak 
        mengendalikan atau menerima pertanggungjawaban atau tanggung jawab untuk situs web ini dan untuk pengumpulan, penggunaan, 
        pemeliharaan, berbagi, atau pengungkapan data dan informasi oleh pihak ketiga tersebut. Silakan baca syarat dan ketentuan dan 
        kebijakan privasi dari situs=</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Iklan yang terdapat pada Aplikasi,situs web, dan Materi Pemasaran kami berfungsi sebagai tautan ke situs web pengiklan dan dengan
        demikian segala informasi yang mereka kumpulkan berdasarkan klik anda pada tautan itu akan dikumpulkan dan digunakan oleh 
        pengiklan yang relevan sesuai dengan kebijakan privasi pengiklan tersebut</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>12.CARA UNTUK MENGHUBUNGI KAMI</Text>
        <Text marginTop='5'>Jika anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau anda ingin mendapatkan akses dan/atau melakukan koreksi
        terhadap Informasi Pribadi anda, silahkan hubungi info@brightgasaja.com</Text>
        </Box>
        <Box height='90'>.</Box>
        </Box>
        <Box bg={'#D30E8A'} px={150} py={30}>
        <Box py={17}>
            <Image width={150} height={50} src='/images/gambar-logo.png'/>
        </Box>
        <Box>
            <Text color={'white'} fontFamily={'arial'} fontSize={20} >Belanja Kebutuhan LPJ cukup lewat satu Aplikasi</Text>
        </Box>
        <Flex py={55} experimental_spaceX={40}>
        <Box color={'white'} fontFamily={'arial'} fontSize={23}>
            <Text>Perusahaan</Text>
            <Text><Link to='/tentang'>Tentang kami</Link></Text>
            <Text><Link to='/layanan'>Layanan</Link></Text>
        </Box>
        <Box color={'white'} fontFamily={'arial'} fontSize={23}>
            <Text>Bantuan</Text>
            <Text><Link className='link1' to='/bantuan'>Pusat bantuan</Link></Text>
        </Box>
        <Box>
            <Box color='white' fontFamily='arial' fontSize='23px'>
                <Text >Kontak</Text>
                <Text>info@brightgasaja.com</Text>
                <Text>(0411) 422961</Text>
                <Text>Jl. A. P. Pettarani No. 18 G,</Text>
                <Text>Tamamaung, Kec. Pannakukang,</Text>
                <Text>Kota Makassar, Sulawesi Selatan</Text>
                <Text>90222</Text>
            </Box>
        </Box>
        </Flex>
        <Flex justifyContent={'space-between'}>
            <Text color='white' fontFamily='calibri' fontSize='20'>Copyright © 2022 Pt. Massindo Gas Energi</Text>
            <Flex experimental_spaceX={2} color={'white'} fontFamily={'calibri'} fontSize={20}><Text><Link className='b' to='/ketentuan'>Ketentuan Layanan</Link></Text><Text>|</Text><Text><Link className='b' to='/kebijakan'>Kebijakan Privasi</Link></Text></Flex>
        </Flex>
        </Box>
        </Box>
    )
}

const HomeMobileContent = () => {
    return (
        <Box>
        <Header/>
        <Box bg={'white'}>
        <Box height='61 '>.</Box>
        <Box px={5} fontFamily={'arial'} fontSize={15}>
        <Text fontFamily='arial' fontSize='30' fontWeight='bold' >Kebijakan Privasi aplikasi Bright Gas</Text>
        <Text marginTop='5'>1. INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
        <Text marginTop='5'>Kami mengumpulkan informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi, menghubungi, atau menemukan 
        orang atau perangkat yang terkait dengan informasi tersebut ("Informasi Pribadi"). Informasi Pribadi termasuk, tetapi tidak terbatas pada,
        nama, alamat, tanggal lahir, pekerjaan, nomor telepon, nomor faks, alamat e-mail, rekening bank dan detail kartu kredit, jenis kelamin,
        identifikasi (termasuk KTP, SIM, atau Paspor) atau tanda pengenal lainnya yang dikeluarkan oleh pemerintah, foto, kewarganegaraan, 
        nomor telepon pengguna dan non-pengguna Aplikasi kami yang terdapat dalam daftar kontak telefon selular anda, serta informasi 
        biometrik (termasuk namun tidak terbatas pengenalan wajah). Selain itu, untuk informasi lainnya, seperti profil pribadi, dan/atau nomor 
        pengenal unik, yang dikaitkan atau digabungkan dengan Informasi Pribadi, maka informasi tersebut juga dianggap sebagai Informasi 
        Pribadi. Informasi Pribadi yang kami kumpulkan dapat diberikan oleh anda secara langsung atau oleh pihak ketiga(misalnya: ketika anda 
        mendaftar atau menggunakan Aplikasi, ketika anda menghubungi layanan pelanggan kami, atau sebaliknya ketika anda memberikan 
        Informasi Pribadi kepada kami). Kami dapat mengumpulkan informasi dalam berbagai macam bentuk dan tujuan (termasuk tujuan yang 
        diizinkan berdasarkan peraturan perundang-undangan yang berlaku.
        <Text  marginTop='5'>- Informasi yang kami  kumpulkan dari anda atau perangkat seluler anda secara langsung</Text>
        <Text  marginTop='5'>Ketika anda mendaftar dan membuat akun dengan kami menggunakan Aplikasi, anda harus memberikan kepada kami Informasi 
        Pribadi tertentu, termasuk nama, alamat fisik, alamat e-mail, nomor telepon anda, dan/atau kata sandi yang akan anda gunakan untuk 
        mengakses Aplikasi setelah pendaftaran. Jika anda menggunakan Aplikasi sebagai penyedia layanan, anda harus memberikan Informasi 
        Pribadi tambahan kepada kami sebagai bagian dari proses pendaftaran penyedia layanan. Ini akan mencakup rincian mengenai 
        kendaraan anda (izin, persetujuan, dan otorisasi lainnya yang anda perlukan untuk mengoperasikan kendaraan dan untuk menyediakan 
        layanan transportasi), polis asuransi anda, dan rekening bank anda untuk keperluan menerima pembayaran.</Text>
        <Text  marginTop='5'>- Ketika anda menggunakan Aplikasi, anda harus memberikan kami informasi yang relevan yang mungkin diperlukan oleh kami agar
        Aplikasi dapat berfungsi, contohnya:
        </Text>
        <Text  marginTop='5'></Text>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda menggunakan Aplikasi sebagai pengguna, anda harus memberikan informasi pada kami sesuai dengan jenis layanan yang 
         anda cari, dan rincian penjemputan dan/atau pengiriman.</Text>
        </Flex>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda menggunakan Aplikasi sebagai penyedia layanan, agar Aplikasi dapat berfungsi, anda harus memberikan kepada kami 
         informasi terkait dengan layanan yang anda dapat terima pesanannya pada saat itu, dan perinciannya pada saat ini, dan setelah 
         pesanan untuk layanan yang ditempatkan oleh pengguna diterima oleh anda sebagai penyedia layanan, anda mungkin akan perlu 
         memberikan kepada kami data lain yang kami perlukan untuk mengelola Aplikasi terkait, serta untuk memantau keseluruhan 
         penggunaan Aplikasi.</Text>
        </Flex>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet 
         elektronik yang disediakan oleh kami, jika anda adalah pihak pembayar, anda akan menyediakan informasi kepada kami yang 
         berkaitan dengan penggunaan, pembayaran atau transfer, termasuk tetapi tidak terbatas pada rincian transfer dan/atau penerima 
         pembayaran, jumlah pembayaran yang dibayarkan, jenis kartu pembayaran yang digunakan, nama penerbit kartu pembayaran 
         tersebut, nama pemegang rekening dan nomor kartu pembayaran tersebut.</Text>
        </Flex>
        <Flex mt={2}>
        <Text ></Text>
        <Text >Jika anda bermaksud untuk mendaftar sebagai pemegang akun terdaftar atau terverifikasi dari fasilitas uang elektronik yang disediak
         an oleh kami, anda akan memberikan informasi kepada kami yang diperlukan , tetapi tidak terbatas pada nama lengkap anda, nomor
         kartu identitas anda, jenis kartu identitas yang anda gunakan untuk registrasi, alamat, jenis kelamin, tempat dan tanggal lahir, 
         pekerjaan, kebangsaan dan/atau data biometrik. </Text>
        </Flex>
        <Text  marginTop='5'>Informasi yang dikumpulkan setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami</Text>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami,kami dapat mengumpulkan data teknis tertentu sehubungan 
        dengan penggunaan anda seperti, alamat protokol internet, informasi halaman web yang sebelumnya atau selanjutnya dilihat, durasi 
        setiap kunjungan/sesi, identitas perangkat internet atau alamat kontrol akses media, dan informasi mengenai produsen, model, dan 
        sistem operasi dari perangkat yang anda gunakan untuk mengakses Aplikasi atau situs web kami.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Ketika anda menggunakan Aplikasi atau mengunjungi situs web kami, informasi tertentu juga dapat dikumpulkan secara otomatis 
        dengan menggunakan cookies. Cookies adalah berkas data kecil yang tersimpan pada komputer atau perangkat seluler anda. Kami 
        menggunakan cookies untuk melacak aktivitas pengguna dengan tujuan untuk meningkatkan antarmuka dan pengalaman pengguna. 
        Sebagian besar perangkat seluler dan peramban internet mendukung penggunaan cookies; namun anda dapat menyesuaikan 
        pengaturan pada perangkat seluler atau peramban internet anda untuk menolak beberapa jenis cookies atau cookies spesifik tertentu. 
        Perangkat seluler dan/atau peramban anda juga memungkinkan anda untuk menghapus cookies apa pun yang sebelumnya telah 
        tersimpan. Namun demikian, hal itu dapat mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi atau situs web kami.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Setiap kali anda menggunakan Aplikasi melalui perangkat seluler, kami akan melacak dan mengumpulkan informasi lokasi geografis 
        anda dalam waktu sebenarnya. Dalam beberapa kasus, anda akan diminta atau diharuskan untuk mengaktifkan Global Positioning 
        System (GPS) pada perangkat seluler anda untuk memungkinkan kami agar dapat memberikan anda pengalaman yang lebih baik 
        dalam menggunakan Aplikasi (misalnya, untuk memberikan informasi tentang seberapa dekat penyedia layanan dengan anda). Anda 
        dapat menonaktifkan informasi pelacakan lokasi geografis pada perangkat seluler anda untuk sementara waktu. Namun, hal ini dapat 
        mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Jika anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet 
        elektronik yang disediakan oleh kami, kami dapat mengumpulkan informasi tertentu terkait dengan sumber dana yang anda gunakan 
        untuk melakukan isi ulang (termasuk rincian rekening bank), rincian rekening penerima penarikan, riwayat transaksi (termasuk detail 
        penerima), rincian tagihan, faktur, dan nomor telepon.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >o</Text>
        <Text >Jika anda menggunakan akun virtual yang disediakan oleh kami untuk menerima pembayaran menggunakan uang elektronik dan
        /atau dompet elektronik dari pembayar, baik anda penyedia layanan atau merchant, kami dapat mengumpulkan informasi tertentu 
        yang terkait dengan penggunaan anda termasuk tetapi tidak terbatas pada layanan dan/atau barang yang ditransaksikan, jumlah
        yang anda kumpulkan dari setiap transaksi, rincian akun penarikan atau penyelesaian pembayaran dan riwayat penarikan atau 
        penyelesaian pembayaran.</Text>
        </Flex>
        <Text  marginTop='5'>Informasi tentang pihak ketiga yang anda berikan kepada kami</Text>
        <Flex mt={2}>
        <Text >a.</Text>
        <Text >Anda dapat memberikan kepada kami Informasi Pribadi yang berkaitan dengan individu pihak ketiga lainnya (termasuk Informasi 
         Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau individu lain). Anda tentu saja akan memerlukan 
         persetujuan dari mereka terlebih dahulu untuk melakukannya lihat “Pengakuan dan Persetujuan”, di bawah, untuk informasi lebih 
         lanjut.</Text>
        </Flex>
        <Flex mt={2}>
        <Text >b.</Text>
        <Text >Kami tidak mewajibkan atau mendukung, ataupun melarang, pemasangan atau penggunaan alat perekam dalam kendaraan di 
         kendaraan apa pun. Jika perangkat tersebut dipasang dan digunakan di kendaraan, kami tidak mengumpulkan Informasi Pribadi 
         apapun dari rekaman dalam kendaraan oleh perangkat tersebut, dan pengumpulan Informasi Pribadi dari rekaman di dalam 
         kendaraan tersebut tidak dilakukan atas nama kami. Pengumpulan Informasi Pribadi dari rekaman di dalam kendaraan tersebut 
         merupakan diskresi dari {<br/>}
         penyedia layanan. Kami tidak memiliki kendali atas pengumpulan Informasi Pribadi tersebut oleh penyedia layanan dan setiap 
         penggunaan atau pengungkapan oleh penyedia layanan dari Informasi Pribadi yang dikumpulkan.Jika anda adalah penyedia layanan 
         dan memilih untuk mengunduh dan menggunakan perangkat rekaman di dalam kendaraan tersebut di kendaraan anda, anda 
         bertanggung jawab sepenuhnya untuk memberitahukan pengguna. Jika anda adalah seorang pengguna dan anda keberatan atas 
         penggunaan alat perekam dalam kendaraan penyedia layanan, anda harus menyampaikan hal tersebut kepada penyedia layanan 
         secara langsung dan hal tersebut menjadi tanggung jawab pribadi anda</Text>
        </Flex>
        <Flex mt={2}>
        <Text >c.</Text>
        <Text >Jika anda berkeinginan untuk menggunakan fitur pesan di Aplikasi kami, anda akan memberikan nomor telefon pengguna kami yang 
         tersimpan di kontak telefon selular anda secara rutin untuk membantu anda dalam menggunakan fitur pesan kami serta tujuan 
         internal lainnya.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>2. PENGGUNAAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
        <Text marginTop='5'>Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan sebagai berikut maupun untuk tujuan lain yang diizinkan 
        oleh peraturan perundang-undangan yang berlaku ("Tujuan"):
        <Text  marginTop='5'> Jika anda adalah pengguna, kami dapat menggunakan Informasi Pribadi anda: </Text>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk mengidentifikasi dan mendaftarkan anda sebagai pengguna dan untuk mengadministrasikan, memverifikasi, menonaktifkan, 
        atau mengelola akun anda;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan kami diperlukan sebelum penyedia layanan 
        memberikan layanan kepada anda atau sebelum kami mendaftarkan anda sebagai pengguna, termasuk proses Mengenal Pelanggan 
        (Know Your Customer).</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memungkinkan penyedia layanan untuk menyediakan layanan yang anda minta.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk mengolah dan memfasilitasi pesanan dan transaksi pembayaran yang anda lakukan.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan 
        preferensi anda sebagai pengguna</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan penggunaan berbagai 
        layanan yang tersedia di Aplikasi</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra kami dan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
	    fontSize='15' >-</Text>
        <Text fontFamily='arial' 
	    fontSize='15' >untuk mengirimkan anda komunikasi pemasaran, iklan, voucher, dan informasi secara langsung atau terfokus, dan informasi tentang 
        penawaran atau promosi khusus.</Text>
        </Flex>
        <Text  marginTop='5'>Jika anda adalah penyedia layanan, kami dapat menggunakan Informasi Pribadi anda</Text>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk mengidentifikasi anda dan mendaftarkan anda sebagai penyedia layanan dan untuk mengadministrasi, mengelola atau 
        memverifikasi Akun anda</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan kami mungkin perlu dilakukan sebelum kami 
        dapat mendaftarkan anda sebagai penyedia layanan.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memungkinkan anda memberikan layanan kepada pengguna</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran kepada anda terkait dengan layanan yang telah anda berikan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk berkomunikasi dengan anda dan mengirimkan anda informasi terkait dengan penyediaan layanan anda, termasuk untuk 
        menyampaikan pesanan pengguna kepada anda dan untuk memfasilitasi penerimaan anda atas pesanan tersebut</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memberikan anda pemberitahuan dan pembaruan pada Aplikasi atau perubahan cara penyediaan layanan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memberi anda laporan terkait dengan layanan yang telah anda sediakan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memproses dan menanggapi saran dari pengguna terhadap layanan yang telah anda berikan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan 
        preferensi anda sebagai penyedia layanan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan daya tanggap 
        penyedia layanan untuk berbagai layanan yang tersedia pada Aplikasi</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memproses dan mengelola reward points anda</Text>
        </Flex>
        <Text  marginTop='5'>Baik anda merupakan pengguna ataupun penyedia layanan atau pihak yang memberikan Informasi Pribadi kepada kami, kami juga 
        dapat menggunakan Informasi Pribadi anda secara lebih umum untuk tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu 
        kami akan bertindak secara wajar dan tidak menggunakan Informasi Pribadi anda lebih dari apa yang diperlukan untuk tujuan tersebut)</Text>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk melakukan proses dan fungsi bisnis terkait;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' > untuk memantau penggunaan Aplikasi dan mengelola, mendukung serta meningkatkan efisiensi kinerja, perkembangan, pengalaman 
        pengguna dan fungsi-fungsi Aplikasi;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memberikan bantuan sehubungan dengan dan untuk menyelesaikan kesulitan teknis atau masalah operasional dengan Aplikasi 
        atau layanan;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk menghasilkan informasi statistik dan data analitik untuk tujuan pengujian, penelitian, analisis, pengembangan produk, kemitraan 
        komersial, dan kerja sama;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk mencegah, mendeteksi dan menyelidiki segala kegiatan yang dilarang, ilegal, tidak sah, atau curang;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memfasilitasi transaksi aset bisnis (yang dapat berupa penggabungan, akuisisi, atau penjualan aset) yang melibatkan kami dan
        /atau afiliasi kami; dan</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >untuk memungkinkan kami mematuhi semua kewajiban berdasarkan peraturan perundang-undangan yang berlaku, (namun tidak 
        terbatas pada menanggapi permintaan, penyelidikan, atau arahan peraturan) dan melakukan pemeriksaan audit, uji tuntas dan 
        penyelidikan.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>3.PEMBERIAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
        <Text marginTop='5'>Kami dapat mengungkapkan atau membagikan Informasi Pribadi anda dengan afiliasi dan pihak lain untuk tujuan sebagai berikut ini 
        serta untuk tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku:
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >jika anda adalah pengguna, untuk memungkinkan penyedia layanan, untuk melakukan atau memberikan layanan;</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >jika disyaratkan atau diotorisasikan oleh peraturan perundang-undangan yang berlaku (termasuk namun tidak terbatas pada 
        menanggapi pertanyaan terkait regulasi, penyelidikan atau pedoman, atau mematuhi persyaratan atau ketentuan pengarsipan dan 
        pelaporan berdasarkan undang-undang), untuk tujuan yang ditentukan dalam peraturan perundang-undangan yang berlaku</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >jika terdapat proses hukum dalam bentuk apapun antara anda dengan kami, atau antara anda dengan pihak lain, sehubungan 
        dengan, atau terkait dengan layanan, untuk keperluan proses hukum tersebut</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >sehubungan dengan segala proses verifikasi yang menurut kami atau penyedia layanan perlu sebelum kami mendaftarkan anda
        sebagai pengguna, termasuk proses Mengenal Pelanggan (Know Your Customer)</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >dalam keadaan darurat terkait kesehatan dan/atau keselamatan anda (baik anda adalah pengguna atau penyedia layanan) untuk 
        keperluan menangani keadaan darurat tersebut</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >sehubungan dengan, penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi semua 
        atau sebagian dari bisnis kami oleh atau ke perusahaan lain, untuk keperluan transaksi tersebut (bahkan jika kemudian transaksi
        tidak dilanjutkan)</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >sehubungan dengan proses klaim asuransi, kami akan membagikan Informasi Pribadi anda untuk tujuan pemrosesan klaim asuransi 
        kepada perusahaan asuransi yang bekerja sama atau berkolaborasi dengan kami</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >kepada pihak ketiga  yang memberikan layanan kepada kami atau anda, melakukan tugas atas nama kami, atau pihak dengan siapa 
        kami mengadakan kerja sama komersial), untuk atau sehubungan dengan tujuan di mana pihak ketiga tersebut terlibat atau tujuan 
        kerja sama kami dengan pihak ketiga tersebut (tergantung keadaannya), yang dapat mencakup diperbolehkannya pihak ketiga 
        tersebut untuk memperkenalkan atau menawarkan produk atau layanan kepada anda, melakukan autentikasi terhadap anda atau 
        menghubungkan anda dengan Akun atau melakukan kegiatan lain termasuk pemasaran,penelitian, nalisis dan pengembangan produk.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >dalam hal kami berbagi Informasi Pribadi dengan afiliasi, kami akan melakukannya dengan maksud agar mereka membantu kami 
        dalam menyediakan Aplikasi, untuk mengoperasikan bisnis kami (termasuk, ketika anda berlangganan milis kami, untuk tujuan 
        pemasaran langsung), atau untuk tujuan pengolahan data atas nama kami.  Misalnya, sebuah afiliasi BRIGHT GAS di negara lain 
        dapat mengolah dan/atau menyimpan Informasi Pribadi anda atas nama perusahaan grup BRIGHT GAS di negara anda. 
        Semua afiliasi kami berkomitmen untuk mengolah Informasi Pribadi yang mereka terima dari kami sesuai dengan Kebijakan Privasi 
        dan peraturan perundang-undangan yang berlaku ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Ketika Informasi Pribadi tidak perlu dikaitkan dengan anda, kami akan melakukan upaya yang wajar untuk menghapus dikaitkannya 
        Informasi Pribadi tersebut dengan anda sebagai individu sebelum mengungkapkan atau berbagi informasi tersebut.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Kami tidak akan menjual atau menyewakan Informasi Pribadi anda.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami dapat mengungkapkan dan membagikan Informasi Pribadi anda jika 
        kami memberitahu anda dan kami telah memperoleh persetujuan anda untuk pengungkapan atau pembagian tersebut.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>4.PENYIMPANAN INFORMASI PRIBADI</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Informasi Pribadi anda hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, atau selama
        penyimpanan tersebut diperlukan atau diperbolehkan oleh peraturan perundang-undangan yang berlaku. Kami akan berhenti 
        menyimpan Informasi Pribadi, atau menghapus maksud dari dikaitkannya Informasi Pribadi tersebut dengan anda sebagai individu,
        segera setelah dianggap bahwa tujuan pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan dengan menyimpan Informasi 
        Pribadi dan penyimpanan tidak lagi diperlukan untuk tujuan bisnis atau secara hukum.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Informasi Pribadi anda disimpan oleh pihak lain (termasuk 
        oleh penyedia layanan, jika anda adalah pengguna; atau oleh pengguna, jika anda adalah penyedia layanan) dengan cara tertentu. 
        Informasi yang disampaikan melalui komunikasi antara pengguna dan penyedia layanan yang dilakukan selain melalui penggunaan
        Aplikasi (seperti melalui panggilan telepon, SMS, pesan seluler atau cara komunikasi lainnya) juga dapat disimpan dengan beberapa 
        cara. Kami tidak mengizinkan dan mendorong penyimpanan Informasi Pribadi dengan cara demikian dan kami tidak bertanggung 
        jawab kepada anda untuk hal tersebut. Kami tidak akan bertanggung jawab atas penyimpanan Informasi Pribadi anda. Anda setuju 
        untuk mengganti rugi, membela, dan membebaskan kami, pejabat, direktur, karyawan, agen, mitra, pemasok, kontraktor, dan afiliasi 
        kami dari dan terhadap setiap dan segala klaim, kerugian, kewajiban, biaya, kerusakan, dan ongkos (termasuk tetapi tidak terbatas 
        pada biaya hukum dan pengeluaran biaya ganti rugi penuh) yang dihasilkan secara langsung atau tidak langsung dari setiap 
        penyimpanan yang tidak sah atas Informasi Pribadi anda.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>5.AKSES DAN KOREKSI INFORMASI PRIBADI</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Tunduk pada peraturan perundang-undangan yang berlaku, anda dapat meminta kepada kami untuk mengakses dan/atau mengoreksi
        Informasi Pribadi anda yang berada dalam kepemilikan dan penguasaan kami, dengan menghubungi kami di perincian yang 
        disediakan di bawah ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Kami berhak menolak permintaan anda untuk mengakses, atau untuk memperbaiki, sebagian atau semua Informasi Pribadi anda 
        yang kami miliki atau kuasai jika diizinkan atau diperlukan berdasarkan perundang-undangan yang berlaku. Hal ini termasuk dalam 
        keadaan di mana Informasi Pribadi tersebut dapat berisi referensi kepada orang lain atau di mana permintaan untuk akses atau 
        permintaan untuk mengoreksi adalah untuk alasan yang kami anggap tidak relevan, tidak serius, atau menyulitkan.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>6.TEMPAT KAMI MENYIMPAN INFORMASI PRIBADI ANDA</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Informasi Pribadi dari anda yang kami kumpulkan dapat disimpan, ditransfer, atau diolah oleh penyedia layanan pihak ketiga. 
        Kami akan menggunakan semua upaya yang wajar untuk memastikan bahwa semua penyedia layanan pihak ketiga tersebut 
        memberikan tingkat perlindungan yang sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Informasi Pribadi anda juga dapat disimpan atau diproses di luar negara anda oleh pihak yang bekerja untuk kami di negara lain,
        atau oleh penyedia layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi kami. Dalam hal tersebut, kami akan 
        memastikan bahwa Informasi Pribadi tersebut tetap menjadi subjek dari tingkat perlindungan yang sebanding dengan apa yang 
        disyaratkan dalam hukum negara anda (dan, dalam hal apapun, sejalan dengan komitmen kami dalam Kebijakan Privasi ini).</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>7.KEAMANAN INFORMASI PRIBADI ANDA</Text>
        <Text marginTop='5'>Kerahasiaan Informasi Pribadi anda adalah hal yang terpenting bagi kami. Kami akan memberlakukan upaya terbaik untuk melindungi 
        dan mengamankan data dan Informasi Pribadi anda dari akses, pengumpulan, penggunaan atau pengungkapan oleh orang-orang 
        yang tidak berwenang dan dari pengolahan yang bertentangan dengan hukum, kehilangan yang tidak disengaja, pemusnahan dan 
        kerusakan atau risiko serupa. Namun, pengiriman informasi melalui internet tidak sepenuhnya aman. Walau kami akan berusaha 
        sebaik mungkin untuk melindungi Informasi Pribadi anda, anda mengakui bahwa kami tidak dapat menjamin keutuhan dan keakuratan 
        Informasi Pribadi apa pun yang anda kirimkan melalui Internet, atau menjamin bahwa Informasi Pribadi tersebut tidak akan dicegat, 
        diakses, diungkapkan, diubah atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor di luar kendali kami. 
        Anda bertanggung jawab untuk menjaga kerahasiaan detail Akun anda, termasuk kata sandi anda dan One Time Password (OTP) 
        dengan siapapun dan harus selalu menjaga dan bertanggung jawab atas keamanan perangkat yang anda gunakan.</Text>
        <Text marginTop='5'>8.PERUBAHAN ATAS KEBIJAKAN PRIVASI INI</Text>
        <Text marginTop='5'>Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan kami sendiri dari waktu ke waktu, untuk memastikan bahwa 
        Kebijakan Privasi ini konsisten dengan perkembangan kami di masa depan, dan/atau perubahan persyaratan hukum atau peraturan. 
        Jika kami memutuskan untuk mengubah Kebijakan Privasi ini, kami akan memberitahu anda tentang perubahan tersebut melalui 
        pemberitahuan umum yang dipublikasikan pada Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail anda yang tercantum 
        dalam Akun anda. Anda setuju bahwa anda bertanggung jawab untuk meninjau Kebijakan Privasi ini secara teratur untuk informasi
        terbaru tentang pengolahan data dan praktik perlindungan data kami, dan bahwa dengan anda terus menggunakan Aplikasi atau situs 
        web kami, berkomunikasi dengan kami, atau mengakses dan menggunakan layanan setelah adanya perubahan apapun terhadap 
        Kebijakan Privasi ini akan dianggap sebagai persetujuan anda terhadap Kebijakan Privasi ini dan segala perubahannya.
        </Text>
        <Text marginTop='5'>9.PENGAKUAN DAN PERSETUJUAN</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Dengan menyetujui Kebijakan Privasi, anda mengakui bahwa anda telah membaca dan memahami Kebijakan Privasi ini dan 
        menyetujui segala ketentuannya. Secara khusus, anda setuju dan memberikan persetujuan kepada kami untuk mengumpulkan,
        menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah Informasi Pribadi anda sesuai dengan 
        Kebijakan Privasi ini.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Dalam keadaan di mana anda memberikan kepada kami Informasi Pribadi yang berkaitan dengan individu lain (seperti Informasi 
        Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau pihak lain), anda menyatakan dan menjamin bahwa 
        anda telah memperoleh persetujuan dari individu tersebut untuk, dan dengan ini menyetujui atas nama individu tersebut untuk, 
        pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi Pribadi mereka oleh kami.</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Anda dapat menarik persetujuan anda untuk setiap atau segala pengumpulan, penggunaan atau pengungkapan Informasi Pribadi 
        anda kapan saja dengan memberikan kepada kami pemberitahuan yang wajar secara tertulis menggunakan rincian kontak yang 
        disebutkan di bawah ini. Anda juga dapat menarik persetujuan pengiriman komunikasi dan informasi tertentu dari kami melalui fasilitas
        "opt-out" atau pilihan "berhenti berlangganan" yang tersedia dalam pesan kami kepada anda. Tergantung pada keadaan dan sifat 
        persetujuan yang anda tarik, anda harus memahami dan mengakui bahwa setelah penarikan persetujuan tersebut, anda mungkin
        tidak lagi dapat menggunakan Aplikasi atau layanan. Penarikan persetujuan anda dapat mengakibatkan penghentian Akun anda atau 
        hubungan kontraktual anda dengan kami, dengan semua hak dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah 
        menerima pemberitahuan untuk menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan Informasi Pribadi anda, 
        kami akan menginformasikan anda tentang konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga anda dapat 
        memutuskan apakah anda tetap ingin menarik persetujuan.</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>10.DATA ANONIM</Text>
        <Text marginTop='5'>Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan Informasi Pribadi, yang tersedia, dengan catatan, (i) bahwa 
        semua hal yang dapat mengidentifikasi telah dihapus sehingga data, baik sendiri atau dikombinasi dengan data lain yang tersedia, 
        tidak dapat dihubungkan dengan atau dikaitkan dengan atau tidak dapat mengidentifikasi suatu individu, dan (ii) data serupa telah 
        digabungkan sehingga data asli membentuk bagian dari kumpulan data yang lebih besar.</Text>
        <Text marginTop='5'>11.PLATFORM PIHAK KETIGA</Text>
        <Text marginTop='5'>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Aplikasi, situs web, dan Materi Pemasaran dapat berisi tautan ke situs web yang dioperasikan oleh pihak ketiga. Kami tidak 
        mengendalikan atau menerima pertanggungjawaban atau tanggung jawab untuk situs web ini dan untuk pengumpulan, penggunaan, 
        pemeliharaan, berbagi, atau pengungkapan data dan informasi oleh pihak ketiga tersebut. Silakan baca syarat dan ketentuan dan 
        kebijakan privasi dari situs=</Text>
        </Flex>
        <Flex mt={2}>
        <Text fontFamily='arial' 
		fontSize='15' >-</Text>
        <Text fontFamily='arial' 
		fontSize='15' >Iklan yang terdapat pada Aplikasi,situs web, dan Materi Pemasaran kami berfungsi sebagai tautan ke situs web pengiklan dan dengan
        demikian segala informasi yang mereka kumpulkan berdasarkan klik anda pada tautan itu akan dikumpulkan dan digunakan oleh 
        pengiklan yang relevan sesuai dengan kebijakan privasi pengiklan tersebut</Text>
        </Flex>
        </Text>
        <Text marginTop='5'>12.CARA UNTUK MENGHUBUNGI KAMI</Text>
        <Text marginTop='5'>Jika anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau anda ingin mendapatkan akses dan/atau melakukan koreksi
        terhadap Informasi Pribadi anda, silahkan hubungi info@brightgasaja.com</Text>
        </Box>
        <Box height='90'>.</Box>
        </Box>
        <Box bg={'#D30E8A'} p={5}>
            <Box experimental_spaceY={2}>
                <Image width={110} height={10} src='/images/gambar-logo.png'/>
                <Text color={'white'} fontFamily={'arial'} fontSize={18} >Belanja Kebutuhan LPJ cukup lewat satu Aplikasi</Text>
            </Box>
            <Flex py={10} experimental_spaceX={20}>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <Text fontWeight={'bold'}>Perusahaan</Text>
                <SimpleGrid>
                    <Link className='link' to='/tentang'>Tentang kami</Link>
                    <Link className='link' to='/layanan'>Layanan</Link>
                </SimpleGrid>  
            </Box>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <SimpleGrid>
                    <Text fontWeight={'bold'}>Bantuan</Text>
                    <Link className='link' to='/bantuan'>Pusat bantuan</Link>
                </SimpleGrid>
            </Box>
            </Flex>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <Text fontWeight={'bold'}>Kontak</Text>
                <Text>info@brightgasaja.com</Text>
                <Text>(0411) 422961</Text>
                <Text>Jl. A. P. Pettarani No. 18 G,</Text>
                <Text>Tamamaung, Kec. Pannakukang,</Text>
                <Text>Kota Makassar, Sulawesi Selatan</Text>
                <Text>90222</Text>
            </Box>
            <Box py={5} experimental_spaceY={3}>
                <Text color={'white'} fontFamily={'arial'} fontSize={15}>Copyright © 2022 Pt. Massindo Gas Energi</Text>
                <Flex color={'white'} fontFamily={'calibri'} fontSize={17} experimental_spaceX={4}>
                    <Link className='b' to='/ketentuan'>Ketentuan Layanan</Link>
                    <Text color={'white'} fontFamily={'Calibri'} fontSize={15}>|</Text>
                    <Link className='b' to='/kebijakan'>Kebijakan Privasi</Link>
                </Flex>
            </Box>
        </Box>
        </Box>
    )
}

const Kebijakan = () => {
    const [isMobile] = useMediaQuery('(max-width: 600px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};

export default Kebijakan;