import React from 'react';
import Header from "./Header";
import { Box, Flex, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

const HomeContentDesktop = () => {
    React.useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: "smooth"})
      },[])
    return (
        <Box>
        <Header/>
        <Box bg={'white'}>
        <Box height={61}></Box>
        <Box px={150} fontFamily={'arial'} fontSize={15}>
        <Text fontFamily='arial' fontSize='45' fontWeight='bold' >Ketentuan Layanan aplikasi Bright Gas</Text>
        <Text  marginTop='8'>Selamat datang di Aplikasi BRIGHT GAS!</Text>
        <Text  marginTop='5'>1. Ketentuan Umum</Text>
        <Text  marginTop='5'>BACALAH SELURUH KETENTUAN PENGGUNAAN INI SEBELUM MENGAKSES ATAU MENGGUNAKAN APLIKASI BRIGHT GAS.</Text>
        <Text  marginTop='5'>Ketentuan Penggunaan ini adalah perjanjian antara pengguna (“Anda”) dan PT MASSINDO GAS ENERGI  (“Kami”), sebuah perseroan 
        terbatas yangdidirikan dan beroperasi secara sah berdasarkan hukum negara Republik Indonesia dan berdomisili di Makassar, Indonesia. 
        Ketentuan Penggunaan ini mengatur akses dan penggunaan Anda atas aplikasi, situs web (www.brightgasaja.com dan situs web lain yang 
        Kami kelola), konten dan produk yang disediakan oleh Kami (selanjutnya, secara bersama-sama disebut sebagai  “Aplikasi BRIGHT GAS”), 
        serta pemesanan, pembayaran atau penggunaan layanan yang tersedia pada Aplikasi BRIGHT GAS (“Layanan”).</Text>
        <Text  marginTop='5'>Dengan menyetujui Ketentuan Penggunaan ini, Anda juga menyetujui Ketentuan Penggunaan tambahan, termasuk Ketentuan Penggunaan 
        pada setiap Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini (selanjutnya, 
        Ketentuan Penggunaan, Ketentuan Penggunaan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Ketentuan 
        Penggunaan”). Meskipun merupakan satu kesatuan, Ketentuan Penggunaan tambahan akan berlaku dalam hal terdapat perbedaan dengan 
        Ketentuan Penggunaan.</Text>
        <Text  marginTop='5'>2. Penggunaan Aplikasi BRIGHT GAS </Text>
        <Text  marginTop='5'>Akses dan penggunaan Aplikasi BRIGHT GAS tunduk pada Ketentuan Penggunaan ini.
        Anda mempunyai kebebasan penuh untuk memilih menggunakan Aplikasi BRIGHT GAS atau aplikasi lain, menggunakan Layanan yang 
        tersedia pada Aplikasi BRIGHT GAS atau tidak, atau berhenti menggunakan Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>3. Pembukaan dan Pengaksesan Akun BRIGHT GAS</Text>
        <Text  marginTop='5'>Sebelum menggunakan Aplikasi BRIGHT GAS, Anda harus menyetujui Ketentuan Penggunaan ini dan Kebijakan Privasi, dan mendaftarkan 
        diri Anda dengan memberikan informasi yang dibutuhkan oleh Kami.  Saat melakukan pendaftaran, Kami akan meminta Anda untuk membe
        rikan nama lengkap, alamat surat elektronik dan nomor telepon genggam pribadi Anda.  Anda dapat mengubah informasi data diri Anda 
        pada fitur pengaturan dalam Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>Setelah melakukan pendaftaran, sistem Kami akan menghasilkan kode verifikasi secara otomatis dan mengirim kode verifikasi tersebut 
        melalui pesan singkat ke nomor telepon genggam yang Anda berikan. Anda perlu melakukan verifikasi dengan memasukan kode verifikasi 
        tersebut pada halaman pendaftaran di Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>Setelah melakukan verifikasi, sistem Kami akan membuatkan akun BRIGHT GAS pribadi (“Akun”) untuk Anda yang dapat digunakan untuk 
        menggunakan Aplikasi BRIGHT GAS dan memesan Layanan melalui Aplikasi BRIGHT GAS.  Nomor telepon genggam Anda melekat pada 
        Akun Anda sehingga Anda tidak bisa membuat Akun baru dengan nomor telepon genggam yang sudah didaftarkan.  Hal yang sama juga 
        berlaku apabila di kemudian hari Anda mengubah nomor telepon genggam Anda pada menu pengaturan di Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>Dalam hal Anda telah keluar dari Akun Anda, maka Anda perlu memasukan alamat surat elektronik atau nomor telepon genggam yang ada
        berikan pada saat mendaftarkan diri Anda dan memasukan kode verifikasi, yang kemudian dikirim secara otomatis oleh sistem Kami ke 
        nomor telepon genggam terdaftar Anda, pada halaman pengaksesan.</Text>
        <Text  marginTop='5'>KODE VERIFIKASI (ONE TIME PASSWORD/OTP) DIHASILKAN SECARA OTOMATIS OLEH SISTEM KAMI. KAMI TIDAK MENGETAHUI 
        DAN TIDAK PERNAH MEMINTA KODE VERIFIKASI ANDA.  JANGAN PERNAH MEMBERITAHUKAN KODE VERIFIKASI ANDA KEPADA 
        SIAPAPUN BAHKAN KEPADA KAMI ATAU PIHAK LAIN YANG MENGAKU SEBAGAI PERWAKILAN KAMI.</Text>
        <Text  marginTop='5'>4. Akun Anda</Text>
        <Text  marginTop='5'>Akun Anda hanya dapat digunakan oleh Anda dan tidak bisa dialihkan kepada orang lain dengan alasan apapun. Kami berhak menolak 
        untuk memfasilitasi pesanan jika Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah mengalihkan atau
        membiarkan Akun Anda digunakan oleh orang lain.</Text>
        <Text  marginTop='5'>Keamanan dan kerahasiaan Akun Anda, termasuk nama terdaftar, alamat surat elektronik terdaftar, nomor telepon genggam terdaftar,rincian 
        pembayaran dan Metode Pembayaran yang Anda pilih, serta kode verifikasi yang dihasilkan dan dikirim oleh sistem Kami atau Penyedia 
        Metode Pembayaran sepenuhnya merupakan tanggung jawab pribadi Anda. Semua kerugian dan risiko yang ada akibat kelalaian Anda 
        menjaga keamanan dan kerahasiaan sebagaimana disebutkan ditanggung oleh Anda sendiri.  Dalam hal demikian, Kami akan menganggap 
        setiap penggunaan atau pesanan yang dilakukan melalui Akun Anda sebagai permintaan yang sah dari Anda.</Text>
        <Text  marginTop='5'>Segera beritahukan Kami jika Anda mengetahui atau menduga bahwa Akun Anda telah digunakan tanpa sepengetahuan dan persetujuan
        Anda. Kami akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.</Text>
        <Text  marginTop='5'>5. Informasi Pribadi</Text>
        <Text  marginTop='5'>Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat surat elektronik, dan 
        nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun tunduk pada Kebijakan Privasi, yang merupakan bagian yang
        tidak terpisahkan dari Ketentuan Penggunaan ini.</Text>
        <Text  marginTop='5'>6. Biaya dan Pembayaran</Text>
        <Text  marginTop='5'>Pengunduhan perangkat lunak dan penggunaan Aplikasi BRIGHT GAS adalah bebas biaya.  Namun, Kami dapat mengenakan biaya untuk 
        penggunaan fitur tertentu pada Aplikasi BRIGHT GAS di kemudian hari.Layanan yang tersedia pada Aplikasi BRIGHT GAS dikenakan biaya 
        yang dapat Anda temukan pada Aplikasi BRIGHT GAS sebelum Anda memesan Layanan tersebut.</Text>
        <Text  marginTop='5'>Kami dapat mengubah atau memperbaharui biaya dari waktu ke waktu berdasarkan faktor tertentu, antara lain lokasi, waktu, jenis Layanan 
        dan peraturan perundang-undangan yang berlaku.  Kami juga dapat membebankan harga dan/atau biaya dengan besaran tertentu sebagai 
        pembayaran untuk penggunaan Aplikasi BRIGHT GAS yang dapat dipungut oleh Kami, afiliasi Kami, atau Penyedia Layanan.</Text>
        <Text  marginTop='5'>Anda dapat melakukan pembayaran terhadap Layanan, Konten Pihak Ketiga atau Penawaran yang Anda akses atau gunakan dengan uang
        tunai atau melalui metode pembayaran elektronik, yang disediakan oleh pihak ketiga independen (“Penyedia Metode Pembayaran”), pada 
        Aplikasi BRIGHT GAS, termasuk uang elektronik, fasilitas pinjaman, kartu debit atau kredit atau metode pembayaran lain 
        (“Metode Pembayaran”) yang dapat berubah sewaktu-waktu berdasarkan kebijakan kami sepenuhnya.</Text>
        <Text  marginTop='5'>Untuk dapat melakukan pembayaran melalui Metode Pembayaran, Anda harus terdaftar secara resmi pada Penyedia Metode Pembayaran 
        yang Anda pilih dan menggunakan kredensial pembayaran Anda sendiri. Dalam hal Anda menggunakan kredensial terdaftar pihak lain, Anda 
        bertanggung jawab secara penuh atas semua perizinan yang diperlukan dan semua kerugian atau sengketa yang timbul antara Anda dan 
        pihak lain tersebut, baik karena kelalaian atau kesalahan Anda, Kami, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia 
        Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Kami berhak menolak atau menunda untuk meneruskan permintaan pembayaran Anda melalui Metode Pembayaran karena alasan tertentu, 
        termasuk namun tidak terbatas pada adanya indikasi atau Kami mempunyai alasan yang cukup untuk menduga adanya kecurangan, 
        penipuan, pelanggaran Ketentuan Penggunaan, pelanggaran atas peraturan perundang-undangan yang berlaku termasuk yang terkait 
        dengan alat pembayaran menggunakan kartu, uang elektronik, pemrosesan transaksi pembayaran, anti pencucian uang, korupsi dan 
        pencegahan pendanaan terorisme, atau tindakan lain yang tidak wajar atau mencurigakan, termasuk belum dipenuhinya kewajiban Anda k
        epada Kami.</Text>
        <Text  marginTop='5'>Ketentuan lebih lanjut tentang biaya yang berlaku terhadap Layanan tertentu, Konten Pihak Ketiga, Penawaran dan/atau Metode Pembayar
        an dapat ditemukan dalam Ketentuan Penggunaan tambahan dari Layanan yang Anda pesan dan syarat dan ketentuan dari Penyedia Laya
        nan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan/atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>7.Perangkat Lunak Aplikasi BRIGHT GAS</Text>
        <Text  marginTop='5'>Kami hanya menyediakan perangkat lunak Aplikasi BRIGHT GAS yang resmi pada pasar digital resmi, seperti Google Play Store atau 
        Apple App Store, dan untuk digunakan hanya pada perangkat telepon genggam atau tablet.  Mengunduh Aplikasi BRIGHT GAS dari tempat
        lain selain pasar digital resmi dan/atau ke dalam perangkat lain selain telepon genggam atau tablet merupakan pelanggaran terhadap 
        Ketentuan Penggunaan ini dan terhadap hak kekayaan intelektual Kami.</Text>
        <Text  marginTop='5'>8. Kekayaan Intelektual</Text>
        <Text  marginTop='5'>Aplikasi BRIGHT GAS dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, 
        basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan 
        hukum Republik Indonesia yang terdaftar baik atas nama Kami ataupun afiliasi Kami.  Kami (dan pemberi lisensi Kami) memiliki seluruh hak 
        dan kepentingan atas Aplikasi BRIGHT GAS dan Layanan, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang 
        terdapat didalamnya dan hak kekayaan intelektual terkait.</Text>
        <Text  marginTop='5'>Tunduk pada Ketentuan Penggunaan ini, Kami memberikan Anda lisensi terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat 
        dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses, dan menggunakan Aplikasi BRIGHT GAS, sebagaimana adanya, hanya 
        pada perangkat berupa telepon genggam dan/atau tablet pribadi Anda dan hanya untuk keperluan terkait penggunaan Layanan, dan (ii) 
        mengakses atau menggunakan konten, informasi dan materi terkait yang tersedia pada Aplikasi BRIGHT GAS hanya untuk kepentingan 
        pribadi dan bukan tujuan komersial. Hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam Ketentuan Penggunaan ini, 
        adalah hak Kami atau pemberi lisensi Kami.</Text>
        <Text  marginTop='5'>Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan atau penawaran untuk menjual, penampilan baik sebagian atau 
        seluruhnya, serta penggunaan Aplikasi BRIGHT GAS dan/atau Layanan, baik secara digital atau lainnya atau pada perangkat selain telepon 
        genggam atau tablet, termasuk namun tidak terbatas pada penggunaan aplikasi tambahan seperti aplikasi modifikasi, emulator, dan lain-lain
        , yang menyimpang dari Ketentuan Penggunaan ini, maupun tujuan penggunaan yang ditentukan oleh Kami, merupakan pelanggaran 
        terhadap hak kekayaan intelektual Kami.</Text>
        <Text  marginTop='5'>Anda tidak boleh:</Text>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >1).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menghapus setiap pemberitahuan hak cipta, merek dagang atau pemberitahuan hak milik lainnya yang terkandung dalam Aplikasi 
        BRIGHT GAS.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >2).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan, memberikan lisensi, menjual, 
        mengalihkan, menampilkan di muka umum baik sebagian maupun seluruhnya, merekayasa balik (reverse engineer), mentransmisikan,
        memindahkan, menyiarkan, menguraikan, atau membongkar bagian manapun dari atau dengan cara lain mengeksploitasi Aplikasi 
        BRIGHT GAS (termasuk perangkat lunak, fitur dan Layanan di dalamnya).</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >3).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >memberikan lisensi, mensublisensikan, menjual, menjual kembali, memindahkan,mengalihkan, mendistribusikan atau mengeksploitasi 
        secara komersial atau membuat tersedia kepada pihak lain Aplikasi BRIGHT GAS dan/atau perangkat lunak dengan cara menciptakan 
        tautan (link) internet ke Aplikasi BRIGHT GAS atau "frame" atau "mirror" setiap perangkat lunak pada server lain atau perangkat 
        nirkabel atau yang berbasis internet.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >4).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada, web spiders, web crawlers, web robots, web ants,
        web indexers, bots,virus atau worm,atau program apapun yang mungkin membuat beberapa permintaan server per detik, menciptakan 
        beban berat atau menghambat operasi dan/atau kinerja Aplikasi BRIGHT GAS.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >5).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menggunakan aplikasi pencarian atau pengambilan kembali situs, perangkat manual atau otomatis lainnya untuk mengambil(scraping),
        indeks (indexing), survei (surveying), tambang.data (data mining), atau dengan cara apapun memperbanyak atau menghindari struktur 
        navigasi atau presentasi dari Aplikasi BRIGHT GAS atau isinya;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >6).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' > menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun materi yang dilindungi hak cipta, merek dagang, atau 
        informasi lain yang Kami miliki tanpa persetujuan tertulis terlebih dahulu dari Kami atau pemilik hak yang melisensikan hak-nya kepada 
        Kami, dan</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >7).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menggunakan dan/atau mengakses secara tidak resmi Aplikasi BRIGHT GAS untuk
        (a) merusak, melemahkan atau membahayakan setiap aspek dari Aplikasi BRIGHT GAS, Layanan atau sistem dan jaringan terkait,dan
        atau(b) membuat produk atau layanan tandingan serupa menggunakan ide, fitur, fungsi atau grafik menyerupai Aplikasi BRIGHT GAS.</Text>
        </Flex>
        <Text  marginTop='5'>9. Tanggung Jawab Anda</Text>
        <Text  marginTop='5'>Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Aplikasi BRIGHT GAS, Layanan, 
        Konten Pihak Ketiga, Penawaran atau Metode Pembayaran.  Anda harus memperlakukan Penyedia Layanan, Penyedia Konten Pihak Ketiga
        , Penyedia Penawaran dan Penyedia Metode Pembayaran dengan hormat dan tidak boleh terlibat dalam perilaku atau tindakan yang tidak 
        sah, mengancam atau melecehkan ketika menggunakan Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran.</Text>
        <Text  marginTop='5'>Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Aplikasi BRIGHT GAS, Layanan, 
        Konten Pihak Ketiga, Penawaran atau Metode Pembayaran melalui Akun Anda, baik oleh Anda atau pihak lain yang menggunakan Akun 
        Anda, dengan cara yang bertentangan dengan Ketentuan Penggunaan ini, Kebijakan Privasi, termasuk syarat dan ketentuan dan kebijakan
        privasi yang ditentukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran, 
        atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan 
        terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishingdan/atau social 
        engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang 
        dapat atau dianggap dapat merusak reputasi Kami.</Text>
        <Text  marginTop='5'>10. Batasan Tanggung Jawab Kami</Text>
        <Text  marginTop='5'>Kami menyediakan Aplikasi BRIGHT GAS sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa keandalan, ketepatan 
        waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Aplikasi BRIGHT GAS dapat memenuhi kebutuhan 
        dan akan sesuai dengan harapan Anda, termasuk namun tidak terbatas pada Layanan, Konten Pihak Ketiga, Penawaran dan Metode 
        Pembayaran yang sepenuhnya menjadi tanggung jawab Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan 
        Penyedia Metode Pembayaran.  Kami tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap kegagalan
        atau kesalahan yang dilakukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode 
        Pembayaran ataupun kegagalan atau kesalahan Anda dalam mematuhi Ketentuan Penggunaan Kami, Penyedia Layanan, Penyedia Konten
        Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Aplikasi BRIGHT GAS dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang terdapat dalam penggunaan internet 
        dan komunikasi elektronik, termasuk perangkat Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau 
        Penyedia Metode Pembayaran rusak, tidak terhubung dengan internet, berada di luar jangkauan, dimatikan atau tidak berfungsi. Kami tidak 
        bertanggung jawab atas keterlambatan, kegagalan pengiriman, kerusakan atau kerugian yang diakibatkan oleh masalah-masalah tersebut.</Text>
        <Text  marginTop='5'>Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas Aplikasi BRIGHT GAS. Namun, Kami tetap melakukan 
        pengawasan untuk tujuan memastikan kelancaran penggunaan Aplikasi BRIGHT GAS dan untuk memastikan kepatuhan terhadap 
        Ketentuan Penggunaan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif 
        atau badan pemerintahan lainnya.</Text>
        <Text  marginTop='5'>Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh 
        Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, terhadap setiap 
        permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau 
        Penyedia Metode Pembayaran. Tetapi, Kami akan memfasilitasi setiap permasalahan atau perselisihan yang timbul antara Anda dan 
        Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran dengan upaya wajar yang 
        diperlukan. Ketika Kami memfasilitasi penyelesaian permasalahan atau perselisihan antara Anda dan Penyedia Layanan, Penyedia Konten
        Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, Kami tidak bertindak sebagai mediator dan hal tersebut tidak 
        menimbulkan kewajiban lebih jauh apapun terhadap Kami.</Text>
        <Text  marginTop='5'>11. Penyelesaian Masalah</Text>
        <Text  marginTop='5'>Apabila Anda mengalami gangguan sistem, mengetahui atau menduga bahwa Akun Anda diretas, digunakan atau disalahgunakan oleh 
        pihak lain, atau apabila perangkat telepon genggam atau tablet pribadi Anda hilang, dicuri, diretas atau terkena virus, segera laporkan 
        kepada Kami sehingga Kami dapat segera mengambil tindakan yang diperlukan untuk menghindari penggunaan, penyalahgunaan, atau 
        kerugian yang timbul atau mungkin timbul lebih lanjut.</Text>
        <Text  marginTop='5'>Apabila Anda mengalami kendala atau masalah terkait Layanan, Konten Pihak Ketiga, Penawaran atau pembayaran melalui Metode 
        Pembayaran, atau perlakuan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran 
        Anda dapat menyampaikan keluhan Anda melalui fitur yang Kami sediakan, termasuk pemberian peringkat dan komentar, atau dengan 
        menghubungi Kami.</Text>
        <Text  marginTop='5'>Untuk menyampaikan keluhan, pertanyaan, sanggahan, dan lain-lain (“Laporan”), Anda perlu memberikan informasi yang cukup, termasuk 
        namun tidak terbatas pada, ringkasan fakta yang terjadi, bukti-bukti yang Anda miliki, nomor pesanan, dan informasi pribadi, seperti alamat 
        surat elektronik dan nomor telepon genggam terdaftar.</Text>
        <Text  marginTop='5'>Untuk menanggapi setiap Laporan yang Anda sampaikan, Kami akan melakukan verifikasi terlebih dahulu dengan mencocokan informasi 
        yang Anda berikan dan informasi pribadi Anda yang terdapat dalam sistem Kami. Jika diperlukan, Kami dapat secara langsung meminta 
        Anda memberikan informasi yang diperlukan untuk tujuan verifikasi.</Text>
        <Text  marginTop='5'>Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi yang Anda berikan tidak cocok dengan informasi pribadi yang 
        terdapat dalam sistem Kami atau apabila Laporan disampaikan terkait, terhadap, atas nama atau oleh pihak lain yang berbeda dengan 
        pemilik Akun yang bersangkutan yang terdaftar secara resmi pada sistem Kami. Kami dapat memberhentikan tindak lanjut terhadap Laporan 
        Anda jika Kami, dengan kebijakan Kami sepenuhnya, menganggap bahwa Laporan Anda tidak didukung oleh fakta-fakta yang cukup dan 
        jelas, atau telah selesai. Kami juga dapat meneruskan Laporan Anda kepada Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia 
        Penawaran atau Penyedia Metode Pembayaran untuk diselesaikan secara langsung oleh Anda dan Penyedia Layanan, Penyedia Konten 
        Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Terhadap Laporan tertentu terkait Layanan, Kami dapat, dengan kebijakan Kami sepenuhnya, memfasilitasi pertemuan antara Anda dan 
        Penyedia Layanan untuk tujuan penyelesaian masalah secara damai dan kekeluargaan. Dalam hal demikian, jika Anda merasa tetap perlu 
        mengambil tindakan lain, termasuk tindakan hukum apapun, Anda dapat melakukannya atas tanggung jawab pribadi Anda sepenuhnya.</Text>
        <Text  marginTop='5'>Terhadap Laporan tertentu terkait Metode Pembayaran, Kami dapat mengembalikan dana Anda melalui cara yang ditentukan oleh Kami atau 
        bersama-sama dengan Penyedia Metode Pembayaran, berdasarkan kebijakan Kami sepenuhnya. Kami tidak akan mengembalikan dana 
        kepada pihak lain yang berbeda dengan kredensial terdaftar pada Akun BRIGHT GAS atau Penyedia Metode Pembayaran,termasuk apabila
        Anda menggunakan kredensial pihak lain untuk melakukan pembayaran melalui Metode Pembayaran.</Text>
        <Text  marginTop='5'>12. Pembekuan Sementara dan Pembekuan Permanen Akun BRIGHT GAS</Text>
        <Text  marginTop='5'>Anda dapat menghapus Aplikasi BRIGHT GAS dari telepon genggam dan/atau tablet Anda setiap saat. Kami tidak memiliki kewajiban 
        apapun kepada Anda terhadap hal-hal yang timbul sejak penghapusan Aplikasi BRIGHT GAS, pembekuan sementara atau pembekuan 
        permanen Akun Anda. Akan tetapi, Anda tetap bertanggung jawab untuk menyelesaikan kewajiban-kewajiban Anda yang telah timbul, 
        termasuk namun tidak terbatas pada, setiap kewajiban yang mungkin timbul akibat adanya sengketa, tuntutan, maupun tindakan hukum 
        lainnya yang telah ada, sebelum tanggal penghapusan Aplikasi BRIGHT GAS, pembekuan sementara atau pembekuan permanen Akun
        Anda.</Text>
        <Text  marginTop='5'>Akun Anda dapat dibekukan untuk sementara waktu atau dapat dibekukan secara permanen karena hal-hal, termasuk namun tidak terbatas 
        pada, sebagai berikut:</Text>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >1).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Laporan Anda bahwa Akun Anda digunakan atau diduga digunakan atau disalahgunakan oleh orang lain;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >2).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Laporan Anda bahwa telepon genggam atau tablet pribadi Anda hilang, dicuri atau diretas;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >3).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Akun Anda telah dialihkan atau digunakan oleh orang lain</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >4).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Kami mengetahui atau dengan alasan yang cukup menduga bahwa telah terjadi hal-hal yang menurut pandangan Kami telah atau dapat 
        merugikan Kami, Anda, Penyedia Layanan atau pihak lainnya;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >5).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Sistem Kami mendeteksi adanya tindakan yang tidak wajar dari penggunaan Akun Anda atau adanya kewajiban berdasarkan Ketentuan 
        Penggunaan, dan/atau Kebijakan Privasi yang tidak dipenuhi oleh Anda; </Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >6).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Anda telah meninggal dunia, ditempatkan di bawah perwalian atau pengampuan atau mengalami ketidakmampuan lainnya yang menja
        dikan Anda tidak cakap hukum berdasarkan peraturan perundang-undangan yang berlaku;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >7).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Penggunaan Aplikasi BRIGHT GAS atau Layanan oleh Anda atau pihak lain (yang menggunakan Akun Anda) dengan cara yang bertent
        angan dengan Ketentuan Penggunaan ini, Kebijakan Privasi atau peraturan perundang-undangan yang berlaku; dan/atau</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >8).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Perintah untuk pembekuan akun, baik sementara atau permanen, yang diterbitkan oleh institusi pemerintah atau moneter terkait atau 
        berdasarkan perintah pengadilan yang diterbitkan sesuai dengan peraturan perundang-undangan yang berlaku.</Text>
        </Flex>
        <Text  marginTop='5'>Jika Akun Anda dibekukan dan Anda memiliki bukti yang jelas bahwa Akun Anda seharusnya tidak dibekukan, Anda dapat membuat Laporan 
        kepada Kami untuk menyampaikan bukti-bukti tersebut. Setelah melakukan pemeriksaan lebih lanjut terhadap Laporan Anda, Kami akan, 
        atas kebijakan Kami sepenuhnya, menentukan untuk mengakhiri atau melanjutkan pembekuan terhadap Akun Anda. Pembekuan tidak akan 
        diteruskan secara tidak wajar apabila Kami memutuskan bahwa hal-hal yang mengakibatkan terjadinya pembekuan telah diselesaikan. </Text>
        <Text  marginTop='5'>13. Tindakan yang Kami Anggap Perlu</Text>
        <Text  marginTop='5'>Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah melakukan tindakan asusila, pelanggaran,</Text>
        <Text  marginTop='5'>kejahatan atau tindakan lain yang bertentangan dengan Ketentuan Penggunaan ini dan/atau peraturan perundang-undangan yang berlaku,
        baik yang dirujuk dalam Ketentuan Penggunaan ini atau tidak, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau
        permanen, atau menghentikan akses Anda terhadap Aplikasi BRIGHT GAS, termasuk Layanan, Konten Pihak Ketiga, Penawaran dan/atau 
        Metode Pembayaran yang terdapat di dalamnya, melakukan pemeriksaan, menuntut ganti kerugian, melaporkan kepada pihak berwenang 
        dan/atau mengambil tindakan lain yang kami anggap perlu, termasuk tindakan hukum pidana maupun perdata.</Text>
        <Text  marginTop='5'>Kami akan menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan
        kepada pihak yang berwajib apabila Kami menerima Laporan adanya pelanggaran yang Anda lakukan atas Ketentuan Penggunaan ini atau
        pun pelanggaran terhadap peraturan perundang-undangan yang berlaku, sehubungan dengan:</Text>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >1).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas pada, atas fisik, jenis kelamin, suku, agama dan ras, yang Anda 
        lakukan terhadap Penyedia Layanan.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >2).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Pelecehan atau kekerasan fisik yang Anda lakukan terhadap Penyedia Layanan; dan/atau</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >3).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Pelecehan atau kekerasan seksual, baik secara verbal maupun fisik, yang Anda lakukan terhadap Penyedia Layanan.</Text>
        </Flex>
        <Text  marginTop='5'>Ketentuan ini juga berlaku sebaliknya apabila Anda mengalami tindakan sebagaimana disebutkan di atas yang dilakukan oleh Penyedia 
        Layanan terhadap Anda.</Text>
        <Text  marginTop='5'>14. Pernyataan Anda</Text>
        <Text  marginTop='5'>Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18(delapan belas) tahun atau sudah menikah dan tidak berada dibawah
        perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini. 
        Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses atau menggunakan Aplikasi BRIGHT GAS, Layanan, Konten Pihak 
        Ketiga, Penawaran atau Metode Pembayaran, Anda menyatakan dan menjamin bahwa tindakan Anda membuka,mengakses atau 
        melakukan aktivitas lain dalam Aplikasi BRIGHT GAS telah disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas 
        mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan
        berdasarkan Ketentuan Penggunaan ini pada waktu Anda dianggap telah dewasa secara hukum.</Text>
        <Text  marginTop='5'>Anda setuju untuk mengakses atau menggunakan Aplikasi BRIGHT GAS, Layanan, Konten Pihak Ketiga, Penawaran atau Metode 
        Pembayaran hanya untuk tujuan sebagaimana ditentukan dalam Ketentuan Penggunaan ini dan tidak menyalahgunakan atau menggunakan
        Aplikasi BRIGHT GAS, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran untuk tujuan penipuan, menyebabkan 
        ketidaknyamanan kepada orang lain, melakukan pemesanan palsu atau yang tindakan-tindakan lain yang dapat atau dianggap dapat
        menimbulkan kerugian dalam bentuk apapun terhadap orang lain.</Text>
        <Text  marginTop='5'>Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari penggunaan Aplikasi BRIGHT GAS, Layanan, Konten Pihak Ketiga, 
        Penawaran dan Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda dengan ini setuju untuk melepaskan Kami dari 
        segala tuntutan apapun sehubungan dengan kerusakan, gangguan, atau bentuk lain dari gangguan sistem yang disebabkan oleh akses
        tidak resmi oleh pihak lain.</Text>
        <Text  marginTop='5'>Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas pada, pejabat, direktur, komisaris, karyawan dan agen Kami, dari 
        setiap dan semua kewajiban, konsekuensi, kerugian baik materiil atau immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau 
        tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat pelanggaran Anda terhadap Ketentuan Penggunaan ini maupun 
        sehubungan dengan tindakan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Dikecualikan dari ketentuan di atas, jika terdapat kerugian langsung akibat pelanggaran Kami terhadap Ketentuan Penggunaan ini, maka 
        Anda setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar jumlah keseluruhan yang senyatanya ditimbulkan atau telah 
        dibayarkan sehubungan dengan peristiwa tersebut.</Text>
        <Text  marginTop='5'>15. Lain-lain</Text>
        <Text  marginTop='5'>Anda mengerti dan setuju bahwa Ketentuan Penggunaan ini merupakan perjanjian dalam bentuk elektronik dan tindakan Anda menekan
        tombol ‘daftar’ saat pembukaan Akun atau tombol ‘masuk’ saat akan mengakses Akun Anda merupakan persetujuan aktif Anda untuk 
        mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Ketentuan Penggunaan ini dan Kebijakan Privasi adalah sah dan 
        mengikat secara hukum dan terus berlaku sepanjang penggunaan Aplikasi BRIGHT GAS dan Layanan oleh Anda.</Text>
        <Text  marginTop='5'>Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Ketentuan Penggunaan atau Kebijakan Privasi yang 
        dibuat dalam bentuk elektronik.</Text>
        <Text  marginTop='5'>Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan Penggunaan ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, 
        Kami dapat mengalihkan hak Kami berdasarkan Ketentuan Penggunaan ini setiap saat kepada pihak lain tanpa perlu mendapatkan 
        persetujuan terlebih dahulu dari atau memberikan pemberitahuan sebelumya kepada Anda.</Text>
        <Text  marginTop='5'>Bila Anda tidak mematuhi atau melanggar ketentuan dalam Ketentuan Penggunaan ini, dan Kami tidak mengambil tindakan secara langsung
        , bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di kemudian hari.</Text>
        <Text  marginTop='5'>Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Aplikasi BRIGHT GAS atau 
        setelah berakhirnya perjanjian ini antara Anda dan Kami.</Text>
        <Text  marginTop='5'>Jika salah satu dari ketentuan dalam Ketentuan Penggunaan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan 
        lainnya.</Text>
        <Text  marginTop='5'>Kami membuat Ketentuan Penggunaan ini dalam bahasa Indonesia dan Inggris. Setiap perbedaan akan diartikan sesuai dengan bahasa 
        Indonesia.</Text>
        <Text  marginTop='5'>Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh perselisihan yang timbul dari penggunaan 
        Aplikasi BRIGHT GAS atau Layanan tunduk pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Selatan.</Text>
        <Text  marginTop='5'>16. Cara Menghubungi Kami</Text>
        <Text  marginTop='5'>Anda dapat menghubungi Kami melalui surat elektronik ke info@BRIGHT GAS.id. Semua korespondensi Anda akan dicatat, direkam dan 
        disimpan untuk arsip Kami.</Text>
        <Text  marginTop='5'>Saya telah membaca dan mengerti seluruh Ketentuan Penggunaan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban
        , dan ketentuan yang diatur di dalamnya.</Text>
        </Box>
        <Box height={61}></Box>
        </Box>
        <Box bg={'#D30E8A'} px={150} py={30}>
        <Box py={17}>
            <Image width={150} height={50} src='/images/gambar-logo.png'/>
        </Box>
        <Box>
            <Text color={'white'} fontFamily={'arial'} fontSize={20} >Belanja Kebutuhan LPJ cukup lewat satu Aplikasi</Text>
        </Box>
        <Flex py={55} experimental_spaceX={40}>
        <Box color={'white'} fontFamily={'arial'} fontSize={23}>
            <Text>Perusahaan</Text>
            <Text><Link to='/tentang'>Tentang kami</Link></Text>
            <Text><Link to='/layanan'>Layanan</Link></Text>
        </Box>
        <Box color={'white'} fontFamily={'arial'} fontSize={23}>
            <Text>Bantuan</Text>
            <Text><Link className='link1' to='/bantuan'>Pusat bantuan</Link></Text>
        </Box>
        <Box>
            <Box color='white' fontFamily='arial' fontSize='23px'>
                <Text >Kontak</Text>
                <Text>info@brightgasaja.com</Text>
                <Text>(0411) 422961</Text>
                <Text>Jl. A. P. Pettarani No. 18 G,</Text>
                <Text>Tamamaung, Kec. Pannakukang,</Text>
                <Text>Kota Makassar, Sulawesi Selatan</Text>
                <Text>90222</Text>
            </Box>
        </Box>
        </Flex>
        <Flex justifyContent={'space-between'}>
            <Text color='white' fontFamily='calibri' fontSize='20'>Copyright © 2022 Pt. Massindo Gas Energi</Text>
            <Flex experimental_spaceX={2} color={'white'} fontFamily={'calibri'} fontSize={20}><Text><Link className='b' to='/ketentuan'>Ketentuan Layanan</Link></Text><Text>|</Text><Text><Link className='b' to='/kebijakan'>Kebijakan Privasi</Link></Text></Flex>
        </Flex>
        </Box>
        </Box>
    )
}

const HomeMobileContent = () => {
    return (
        <Box>
        <Header/>
        <Box bg={'white'}>
        <Box height={61}></Box>
        <Box px={5} fontFamily={'arial'} fontSize={15}>
        <Text fontFamily='arial' fontSize='30' fontWeight='bold' >Ketentuan Layanan aplikasi Bright Gas</Text>
        <Text  marginTop='8'>Selamat datang di Aplikasi BRIGHT GAS!</Text>
        <Text  marginTop='5'>1. Ketentuan Umum</Text>
        <Text  marginTop='5'>BACALAH SELURUH KETENTUAN PENGGUNAAN INI SEBELUM MENGAKSES ATAU MENGGUNAKAN APLIKASI BRIGHT GAS.</Text>
        <Text  marginTop='5'>Ketentuan Penggunaan ini adalah perjanjian antara pengguna (“Anda”) dan PT MASSINDO GAS ENERGI  (“Kami”), sebuah perseroan 
        terbatas yangdidirikan dan beroperasi secara sah berdasarkan hukum negara Republik Indonesia dan berdomisili di Makassar, Indonesia. 
        Ketentuan Penggunaan ini mengatur akses dan penggunaan Anda atas aplikasi, situs web (www.brightgasaja.com dan situs web lain yang 
        Kami kelola), konten dan produk yang disediakan oleh Kami (selanjutnya, secara bersama-sama disebut sebagai  “Aplikasi BRIGHT GAS”), 
        serta pemesanan, pembayaran atau penggunaan layanan yang tersedia pada Aplikasi BRIGHT GAS (“Layanan”).</Text>
        <Text  marginTop='5'>Dengan menyetujui Ketentuan Penggunaan ini, Anda juga menyetujui Ketentuan Penggunaan tambahan, termasuk Ketentuan Penggunaan 
        pada setiap Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini (selanjutnya, 
        Ketentuan Penggunaan, Ketentuan Penggunaan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Ketentuan 
        Penggunaan”). Meskipun merupakan satu kesatuan, Ketentuan Penggunaan tambahan akan berlaku dalam hal terdapat perbedaan dengan 
        Ketentuan Penggunaan.</Text>
        <Text  marginTop='5'>2. Penggunaan Aplikasi BRIGHT GAS </Text>
        <Text  marginTop='5'>Akses dan penggunaan Aplikasi BRIGHT GAS tunduk pada Ketentuan Penggunaan ini.
        Anda mempunyai kebebasan penuh untuk memilih menggunakan Aplikasi BRIGHT GAS atau aplikasi lain, menggunakan Layanan yang 
        tersedia pada Aplikasi BRIGHT GAS atau tidak, atau berhenti menggunakan Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>3. Pembukaan dan Pengaksesan Akun BRIGHT GAS</Text>
        <Text  marginTop='5'>Sebelum menggunakan Aplikasi BRIGHT GAS, Anda harus menyetujui Ketentuan Penggunaan ini dan Kebijakan Privasi, dan mendaftarkan 
        diri Anda dengan memberikan informasi yang dibutuhkan oleh Kami.  Saat melakukan pendaftaran, Kami akan meminta Anda untuk membe
        rikan nama lengkap, alamat surat elektronik dan nomor telepon genggam pribadi Anda.  Anda dapat mengubah informasi data diri Anda 
        pada fitur pengaturan dalam Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>Setelah melakukan pendaftaran, sistem Kami akan menghasilkan kode verifikasi secara otomatis dan mengirim kode verifikasi tersebut 
        melalui pesan singkat ke nomor telepon genggam yang Anda berikan. Anda perlu melakukan verifikasi dengan memasukan kode verifikasi 
        tersebut pada halaman pendaftaran di Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>Setelah melakukan verifikasi, sistem Kami akan membuatkan akun BRIGHT GAS pribadi (“Akun”) untuk Anda yang dapat digunakan untuk 
        menggunakan Aplikasi BRIGHT GAS dan memesan Layanan melalui Aplikasi BRIGHT GAS.  Nomor telepon genggam Anda melekat pada 
        Akun Anda sehingga Anda tidak bisa membuat Akun baru dengan nomor telepon genggam yang sudah didaftarkan.  Hal yang sama juga 
        berlaku apabila di kemudian hari Anda mengubah nomor telepon genggam Anda pada menu pengaturan di Aplikasi BRIGHT GAS.</Text>
        <Text  marginTop='5'>Dalam hal Anda telah keluar dari Akun Anda, maka Anda perlu memasukan alamat surat elektronik atau nomor telepon genggam yang ada
        berikan pada saat mendaftarkan diri Anda dan memasukan kode verifikasi, yang kemudian dikirim secara otomatis oleh sistem Kami ke 
        nomor telepon genggam terdaftar Anda, pada halaman pengaksesan.</Text>
        <Text  marginTop='5'>KODE VERIFIKASI (ONE TIME PASSWORD/OTP) DIHASILKAN SECARA OTOMATIS OLEH SISTEM KAMI. KAMI TIDAK MENGETAHUI 
        DAN TIDAK PERNAH MEMINTA KODE VERIFIKASI ANDA.  JANGAN PERNAH MEMBERITAHUKAN KODE VERIFIKASI ANDA KEPADA 
        SIAPAPUN BAHKAN KEPADA KAMI ATAU PIHAK LAIN YANG MENGAKU SEBAGAI PERWAKILAN KAMI.</Text>
        <Text  marginTop='5'>4. Akun Anda</Text>
        <Text  marginTop='5'>Akun Anda hanya dapat digunakan oleh Anda dan tidak bisa dialihkan kepada orang lain dengan alasan apapun. Kami berhak menolak 
        untuk memfasilitasi pesanan jika Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah mengalihkan atau
        membiarkan Akun Anda digunakan oleh orang lain.</Text>
        <Text  marginTop='5'>Keamanan dan kerahasiaan Akun Anda, termasuk nama terdaftar, alamat surat elektronik terdaftar, nomor telepon genggam terdaftar,rincian 
        pembayaran dan Metode Pembayaran yang Anda pilih, serta kode verifikasi yang dihasilkan dan dikirim oleh sistem Kami atau Penyedia 
        Metode Pembayaran sepenuhnya merupakan tanggung jawab pribadi Anda. Semua kerugian dan risiko yang ada akibat kelalaian Anda 
        menjaga keamanan dan kerahasiaan sebagaimana disebutkan ditanggung oleh Anda sendiri.  Dalam hal demikian, Kami akan menganggap 
        setiap penggunaan atau pesanan yang dilakukan melalui Akun Anda sebagai permintaan yang sah dari Anda.</Text>
        <Text  marginTop='5'>Segera beritahukan Kami jika Anda mengetahui atau menduga bahwa Akun Anda telah digunakan tanpa sepengetahuan dan persetujuan
        Anda. Kami akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.</Text>
        <Text  marginTop='5'>5. Informasi Pribadi</Text>
        <Text  marginTop='5'>Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat surat elektronik, dan 
        nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun tunduk pada Kebijakan Privasi, yang merupakan bagian yang
        tidak terpisahkan dari Ketentuan Penggunaan ini.</Text>
        <Text  marginTop='5'>6. Biaya dan Pembayaran</Text>
        <Text  marginTop='5'>Pengunduhan perangkat lunak dan penggunaan Aplikasi BRIGHT GAS adalah bebas biaya.  Namun, Kami dapat mengenakan biaya untuk 
        penggunaan fitur tertentu pada Aplikasi BRIGHT GAS di kemudian hari.Layanan yang tersedia pada Aplikasi BRIGHT GAS dikenakan biaya 
        yang dapat Anda temukan pada Aplikasi BRIGHT GAS sebelum Anda memesan Layanan tersebut.</Text>
        <Text  marginTop='5'>Kami dapat mengubah atau memperbaharui biaya dari waktu ke waktu berdasarkan faktor tertentu, antara lain lokasi, waktu, jenis Layanan 
        dan peraturan perundang-undangan yang berlaku.  Kami juga dapat membebankan harga dan/atau biaya dengan besaran tertentu sebagai 
        pembayaran untuk penggunaan Aplikasi BRIGHT GAS yang dapat dipungut oleh Kami, afiliasi Kami, atau Penyedia Layanan.</Text>
        <Text  marginTop='5'>Anda dapat melakukan pembayaran terhadap Layanan, Konten Pihak Ketiga atau Penawaran yang Anda akses atau gunakan dengan uang
        tunai atau melalui metode pembayaran elektronik, yang disediakan oleh pihak ketiga independen (“Penyedia Metode Pembayaran”), pada 
        Aplikasi BRIGHT GAS, termasuk uang elektronik, fasilitas pinjaman, kartu debit atau kredit atau metode pembayaran lain 
        (“Metode Pembayaran”) yang dapat berubah sewaktu-waktu berdasarkan kebijakan kami sepenuhnya.</Text>
        <Text  marginTop='5'>Untuk dapat melakukan pembayaran melalui Metode Pembayaran, Anda harus terdaftar secara resmi pada Penyedia Metode Pembayaran 
        yang Anda pilih dan menggunakan kredensial pembayaran Anda sendiri. Dalam hal Anda menggunakan kredensial terdaftar pihak lain, Anda 
        bertanggung jawab secara penuh atas semua perizinan yang diperlukan dan semua kerugian atau sengketa yang timbul antara Anda dan 
        pihak lain tersebut, baik karena kelalaian atau kesalahan Anda, Kami, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia 
        Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Kami berhak menolak atau menunda untuk meneruskan permintaan pembayaran Anda melalui Metode Pembayaran karena alasan tertentu, 
        termasuk namun tidak terbatas pada adanya indikasi atau Kami mempunyai alasan yang cukup untuk menduga adanya kecurangan, 
        penipuan, pelanggaran Ketentuan Penggunaan, pelanggaran atas peraturan perundang-undangan yang berlaku termasuk yang terkait 
        dengan alat pembayaran menggunakan kartu, uang elektronik, pemrosesan transaksi pembayaran, anti pencucian uang, korupsi dan 
        pencegahan pendanaan terorisme, atau tindakan lain yang tidak wajar atau mencurigakan, termasuk belum dipenuhinya kewajiban Anda k
        epada Kami.</Text>
        <Text  marginTop='5'>Ketentuan lebih lanjut tentang biaya yang berlaku terhadap Layanan tertentu, Konten Pihak Ketiga, Penawaran dan/atau Metode Pembayar
        an dapat ditemukan dalam Ketentuan Penggunaan tambahan dari Layanan yang Anda pesan dan syarat dan ketentuan dari Penyedia Laya
        nan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan/atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>7.Perangkat Lunak Aplikasi BRIGHT GAS</Text>
        <Text  marginTop='5'>Kami hanya menyediakan perangkat lunak Aplikasi BRIGHT GAS yang resmi pada pasar digital resmi, seperti Google Play Store atau 
        Apple App Store, dan untuk digunakan hanya pada perangkat telepon genggam atau tablet.  Mengunduh Aplikasi BRIGHT GAS dari tempat
        lain selain pasar digital resmi dan/atau ke dalam perangkat lain selain telepon genggam atau tablet merupakan pelanggaran terhadap 
        Ketentuan Penggunaan ini dan terhadap hak kekayaan intelektual Kami.</Text>
        <Text  marginTop='5'>8. Kekayaan Intelektual</Text>
        <Text  marginTop='5'>Aplikasi BRIGHT GAS dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, 
        basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan 
        hukum Republik Indonesia yang terdaftar baik atas nama Kami ataupun afiliasi Kami.  Kami (dan pemberi lisensi Kami) memiliki seluruh hak 
        dan kepentingan atas Aplikasi BRIGHT GAS dan Layanan, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang 
        terdapat didalamnya dan hak kekayaan intelektual terkait.</Text>
        <Text  marginTop='5'>Tunduk pada Ketentuan Penggunaan ini, Kami memberikan Anda lisensi terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat 
        dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses, dan menggunakan Aplikasi BRIGHT GAS, sebagaimana adanya, hanya 
        pada perangkat berupa telepon genggam dan/atau tablet pribadi Anda dan hanya untuk keperluan terkait penggunaan Layanan, dan (ii) 
        mengakses atau menggunakan konten, informasi dan materi terkait yang tersedia pada Aplikasi BRIGHT GAS hanya untuk kepentingan 
        pribadi dan bukan tujuan komersial. Hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam Ketentuan Penggunaan ini, 
        adalah hak Kami atau pemberi lisensi Kami.</Text>
        <Text  marginTop='5'>Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan atau penawaran untuk menjual, penampilan baik sebagian atau 
        seluruhnya, serta penggunaan Aplikasi BRIGHT GAS dan/atau Layanan, baik secara digital atau lainnya atau pada perangkat selain telepon 
        genggam atau tablet, termasuk namun tidak terbatas pada penggunaan aplikasi tambahan seperti aplikasi modifikasi, emulator, dan lain-lain
        , yang menyimpang dari Ketentuan Penggunaan ini, maupun tujuan penggunaan yang ditentukan oleh Kami, merupakan pelanggaran 
        terhadap hak kekayaan intelektual Kami.</Text>
        <Text  marginTop='5'>Anda tidak boleh:</Text>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >1).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menghapus setiap pemberitahuan hak cipta, merek dagang atau pemberitahuan hak milik lainnya yang terkandung dalam Aplikasi 
        BRIGHT GAS.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >2).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan, memberikan lisensi, menjual, 
        mengalihkan, menampilkan di muka umum baik sebagian maupun seluruhnya, merekayasa balik (reverse engineer), mentransmisikan,
        memindahkan, menyiarkan, menguraikan, atau membongkar bagian manapun dari atau dengan cara lain mengeksploitasi Aplikasi 
        BRIGHT GAS (termasuk perangkat lunak, fitur dan Layanan di dalamnya).</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >3).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >memberikan lisensi, mensublisensikan, menjual, menjual kembali, memindahkan,mengalihkan, mendistribusikan atau mengeksploitasi 
        secara komersial atau membuat tersedia kepada pihak lain Aplikasi BRIGHT GAS dan/atau perangkat lunak dengan cara menciptakan 
        tautan (link) internet ke Aplikasi BRIGHT GAS atau "frame" atau "mirror" setiap perangkat lunak pada server lain atau perangkat 
        nirkabel atau yang berbasis internet.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >4).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada, web spiders, web crawlers, web robots, web ants,
        web indexers, bots,virus atau worm,atau program apapun yang mungkin membuat beberapa permintaan server per detik, menciptakan 
        beban berat atau menghambat operasi dan/atau kinerja Aplikasi BRIGHT GAS.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >5).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menggunakan aplikasi pencarian atau pengambilan kembali situs, perangkat manual atau otomatis lainnya untuk mengambil(scraping),
        indeks (indexing), survei (surveying), tambang.data (data mining), atau dengan cara apapun memperbanyak atau menghindari struktur 
        navigasi atau presentasi dari Aplikasi BRIGHT GAS atau isinya;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >6).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' > menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun materi yang dilindungi hak cipta, merek dagang, atau 
        informasi lain yang Kami miliki tanpa persetujuan tertulis terlebih dahulu dari Kami atau pemilik hak yang melisensikan hak-nya kepada 
        Kami, dan</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >7).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >menggunakan dan/atau mengakses secara tidak resmi Aplikasi BRIGHT GAS untuk
        (a) merusak, melemahkan atau membahayakan setiap aspek dari Aplikasi BRIGHT GAS, Layanan atau sistem dan jaringan terkait,dan
        atau(b) membuat produk atau layanan tandingan serupa menggunakan ide, fitur, fungsi atau grafik menyerupai Aplikasi BRIGHT GAS.</Text>
        </Flex>
        <Text  marginTop='5'>9. Tanggung Jawab Anda</Text>
        <Text  marginTop='5'>Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Aplikasi BRIGHT GAS, Layanan, 
        Konten Pihak Ketiga, Penawaran atau Metode Pembayaran.  Anda harus memperlakukan Penyedia Layanan, Penyedia Konten Pihak Ketiga
        , Penyedia Penawaran dan Penyedia Metode Pembayaran dengan hormat dan tidak boleh terlibat dalam perilaku atau tindakan yang tidak 
        sah, mengancam atau melecehkan ketika menggunakan Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran.</Text>
        <Text  marginTop='5'>Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Aplikasi BRIGHT GAS, Layanan, 
        Konten Pihak Ketiga, Penawaran atau Metode Pembayaran melalui Akun Anda, baik oleh Anda atau pihak lain yang menggunakan Akun 
        Anda, dengan cara yang bertentangan dengan Ketentuan Penggunaan ini, Kebijakan Privasi, termasuk syarat dan ketentuan dan kebijakan
        privasi yang ditentukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran, 
        atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan 
        terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishingdan/atau social 
        engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang 
        dapat atau dianggap dapat merusak reputasi Kami.</Text>
        <Text  marginTop='5'>10. Batasan Tanggung Jawab Kami</Text>
        <Text  marginTop='5'>Kami menyediakan Aplikasi BRIGHT GAS sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa keandalan, ketepatan 
        waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Aplikasi BRIGHT GAS dapat memenuhi kebutuhan 
        dan akan sesuai dengan harapan Anda, termasuk namun tidak terbatas pada Layanan, Konten Pihak Ketiga, Penawaran dan Metode 
        Pembayaran yang sepenuhnya menjadi tanggung jawab Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan 
        Penyedia Metode Pembayaran.  Kami tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap kegagalan
        atau kesalahan yang dilakukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode 
        Pembayaran ataupun kegagalan atau kesalahan Anda dalam mematuhi Ketentuan Penggunaan Kami, Penyedia Layanan, Penyedia Konten
        Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Aplikasi BRIGHT GAS dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang terdapat dalam penggunaan internet 
        dan komunikasi elektronik, termasuk perangkat Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau 
        Penyedia Metode Pembayaran rusak, tidak terhubung dengan internet, berada di luar jangkauan, dimatikan atau tidak berfungsi. Kami tidak 
        bertanggung jawab atas keterlambatan, kegagalan pengiriman, kerusakan atau kerugian yang diakibatkan oleh masalah-masalah tersebut.</Text>
        <Text  marginTop='5'>Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas Aplikasi BRIGHT GAS. Namun, Kami tetap melakukan 
        pengawasan untuk tujuan memastikan kelancaran penggunaan Aplikasi BRIGHT GAS dan untuk memastikan kepatuhan terhadap 
        Ketentuan Penggunaan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif 
        atau badan pemerintahan lainnya.</Text>
        <Text  marginTop='5'>Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh 
        Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, terhadap setiap 
        permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau 
        Penyedia Metode Pembayaran. Tetapi, Kami akan memfasilitasi setiap permasalahan atau perselisihan yang timbul antara Anda dan 
        Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran dengan upaya wajar yang 
        diperlukan. Ketika Kami memfasilitasi penyelesaian permasalahan atau perselisihan antara Anda dan Penyedia Layanan, Penyedia Konten
        Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, Kami tidak bertindak sebagai mediator dan hal tersebut tidak 
        menimbulkan kewajiban lebih jauh apapun terhadap Kami.</Text>
        <Text  marginTop='5'>11. Penyelesaian Masalah</Text>
        <Text  marginTop='5'>Apabila Anda mengalami gangguan sistem, mengetahui atau menduga bahwa Akun Anda diretas, digunakan atau disalahgunakan oleh 
        pihak lain, atau apabila perangkat telepon genggam atau tablet pribadi Anda hilang, dicuri, diretas atau terkena virus, segera laporkan 
        kepada Kami sehingga Kami dapat segera mengambil tindakan yang diperlukan untuk menghindari penggunaan, penyalahgunaan, atau 
        kerugian yang timbul atau mungkin timbul lebih lanjut.</Text>
        <Text  marginTop='5'>Apabila Anda mengalami kendala atau masalah terkait Layanan, Konten Pihak Ketiga, Penawaran atau pembayaran melalui Metode 
        Pembayaran, atau perlakuan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran 
        Anda dapat menyampaikan keluhan Anda melalui fitur yang Kami sediakan, termasuk pemberian peringkat dan komentar, atau dengan 
        menghubungi Kami.</Text>
        <Text  marginTop='5'>Untuk menyampaikan keluhan, pertanyaan, sanggahan, dan lain-lain (“Laporan”), Anda perlu memberikan informasi yang cukup, termasuk 
        namun tidak terbatas pada, ringkasan fakta yang terjadi, bukti-bukti yang Anda miliki, nomor pesanan, dan informasi pribadi, seperti alamat 
        surat elektronik dan nomor telepon genggam terdaftar.</Text>
        <Text  marginTop='5'>Untuk menanggapi setiap Laporan yang Anda sampaikan, Kami akan melakukan verifikasi terlebih dahulu dengan mencocokan informasi 
        yang Anda berikan dan informasi pribadi Anda yang terdapat dalam sistem Kami. Jika diperlukan, Kami dapat secara langsung meminta 
        Anda memberikan informasi yang diperlukan untuk tujuan verifikasi.</Text>
        <Text  marginTop='5'>Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi yang Anda berikan tidak cocok dengan informasi pribadi yang 
        terdapat dalam sistem Kami atau apabila Laporan disampaikan terkait, terhadap, atas nama atau oleh pihak lain yang berbeda dengan 
        pemilik Akun yang bersangkutan yang terdaftar secara resmi pada sistem Kami. Kami dapat memberhentikan tindak lanjut terhadap Laporan 
        Anda jika Kami, dengan kebijakan Kami sepenuhnya, menganggap bahwa Laporan Anda tidak didukung oleh fakta-fakta yang cukup dan 
        jelas, atau telah selesai. Kami juga dapat meneruskan Laporan Anda kepada Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia 
        Penawaran atau Penyedia Metode Pembayaran untuk diselesaikan secara langsung oleh Anda dan Penyedia Layanan, Penyedia Konten 
        Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Terhadap Laporan tertentu terkait Layanan, Kami dapat, dengan kebijakan Kami sepenuhnya, memfasilitasi pertemuan antara Anda dan 
        Penyedia Layanan untuk tujuan penyelesaian masalah secara damai dan kekeluargaan. Dalam hal demikian, jika Anda merasa tetap perlu 
        mengambil tindakan lain, termasuk tindakan hukum apapun, Anda dapat melakukannya atas tanggung jawab pribadi Anda sepenuhnya.</Text>
        <Text  marginTop='5'>Terhadap Laporan tertentu terkait Metode Pembayaran, Kami dapat mengembalikan dana Anda melalui cara yang ditentukan oleh Kami atau 
        bersama-sama dengan Penyedia Metode Pembayaran, berdasarkan kebijakan Kami sepenuhnya. Kami tidak akan mengembalikan dana 
        kepada pihak lain yang berbeda dengan kredensial terdaftar pada Akun BRIGHT GAS atau Penyedia Metode Pembayaran,termasuk apabila
        Anda menggunakan kredensial pihak lain untuk melakukan pembayaran melalui Metode Pembayaran.</Text>
        <Text  marginTop='5'>12. Pembekuan Sementara dan Pembekuan Permanen Akun BRIGHT GAS</Text>
        <Text  marginTop='5'>Anda dapat menghapus Aplikasi BRIGHT GAS dari telepon genggam dan/atau tablet Anda setiap saat. Kami tidak memiliki kewajiban 
        apapun kepada Anda terhadap hal-hal yang timbul sejak penghapusan Aplikasi BRIGHT GAS, pembekuan sementara atau pembekuan 
        permanen Akun Anda. Akan tetapi, Anda tetap bertanggung jawab untuk menyelesaikan kewajiban-kewajiban Anda yang telah timbul, 
        termasuk namun tidak terbatas pada, setiap kewajiban yang mungkin timbul akibat adanya sengketa, tuntutan, maupun tindakan hukum 
        lainnya yang telah ada, sebelum tanggal penghapusan Aplikasi BRIGHT GAS, pembekuan sementara atau pembekuan permanen Akun
        Anda.</Text>
        <Text  marginTop='5'>Akun Anda dapat dibekukan untuk sementara waktu atau dapat dibekukan secara permanen karena hal-hal, termasuk namun tidak terbatas 
        pada, sebagai berikut:</Text>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >1).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Laporan Anda bahwa Akun Anda digunakan atau diduga digunakan atau disalahgunakan oleh orang lain;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >2).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Laporan Anda bahwa telepon genggam atau tablet pribadi Anda hilang, dicuri atau diretas;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >3).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Akun Anda telah dialihkan atau digunakan oleh orang lain</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >4).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Kami mengetahui atau dengan alasan yang cukup menduga bahwa telah terjadi hal-hal yang menurut pandangan Kami telah atau dapat 
        merugikan Kami, Anda, Penyedia Layanan atau pihak lainnya;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >5).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Sistem Kami mendeteksi adanya tindakan yang tidak wajar dari penggunaan Akun Anda atau adanya kewajiban berdasarkan Ketentuan 
        Penggunaan, dan/atau Kebijakan Privasi yang tidak dipenuhi oleh Anda; </Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >6).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Anda telah meninggal dunia, ditempatkan di bawah perwalian atau pengampuan atau mengalami ketidakmampuan lainnya yang menja
        dikan Anda tidak cakap hukum berdasarkan peraturan perundang-undangan yang berlaku;</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >7).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Penggunaan Aplikasi BRIGHT GAS atau Layanan oleh Anda atau pihak lain (yang menggunakan Akun Anda) dengan cara yang bertent
        angan dengan Ketentuan Penggunaan ini, Kebijakan Privasi atau peraturan perundang-undangan yang berlaku; dan/atau</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >8).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Perintah untuk pembekuan akun, baik sementara atau permanen, yang diterbitkan oleh institusi pemerintah atau moneter terkait atau 
        berdasarkan perintah pengadilan yang diterbitkan sesuai dengan peraturan perundang-undangan yang berlaku.</Text>
        </Flex>
        <Text  marginTop='5'>Jika Akun Anda dibekukan dan Anda memiliki bukti yang jelas bahwa Akun Anda seharusnya tidak dibekukan, Anda dapat membuat Laporan 
        kepada Kami untuk menyampaikan bukti-bukti tersebut. Setelah melakukan pemeriksaan lebih lanjut terhadap Laporan Anda, Kami akan, 
        atas kebijakan Kami sepenuhnya, menentukan untuk mengakhiri atau melanjutkan pembekuan terhadap Akun Anda. Pembekuan tidak akan 
        diteruskan secara tidak wajar apabila Kami memutuskan bahwa hal-hal yang mengakibatkan terjadinya pembekuan telah diselesaikan. </Text>
        <Text  marginTop='5'>13. Tindakan yang Kami Anggap Perlu</Text>
        <Text  marginTop='5'>Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah melakukan tindakan asusila, pelanggaran,</Text>
        <Text  marginTop='5'>kejahatan atau tindakan lain yang bertentangan dengan Ketentuan Penggunaan ini dan/atau peraturan perundang-undangan yang berlaku,
        baik yang dirujuk dalam Ketentuan Penggunaan ini atau tidak, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau
        permanen, atau menghentikan akses Anda terhadap Aplikasi BRIGHT GAS, termasuk Layanan, Konten Pihak Ketiga, Penawaran dan/atau 
        Metode Pembayaran yang terdapat di dalamnya, melakukan pemeriksaan, menuntut ganti kerugian, melaporkan kepada pihak berwenang 
        dan/atau mengambil tindakan lain yang kami anggap perlu, termasuk tindakan hukum pidana maupun perdata.</Text>
        <Text  marginTop='5'>Kami akan menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan
        kepada pihak yang berwajib apabila Kami menerima Laporan adanya pelanggaran yang Anda lakukan atas Ketentuan Penggunaan ini atau
        pun pelanggaran terhadap peraturan perundang-undangan yang berlaku, sehubungan dengan:</Text>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >1).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas pada, atas fisik, jenis kelamin, suku, agama dan ras, yang Anda 
        lakukan terhadap Penyedia Layanan.</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >2).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Pelecehan atau kekerasan fisik yang Anda lakukan terhadap Penyedia Layanan; dan/atau</Text>
        </Flex>
        <Flex mt={10} experimental_spaceX={2}>
        <Text fontFamily='arial' 
		fontSize='15' >3).</Text>
        <Text marginLeft='2' fontFamily='arial' 
		fontSize='15' >Pelecehan atau kekerasan seksual, baik secara verbal maupun fisik, yang Anda lakukan terhadap Penyedia Layanan.</Text>
        </Flex>
        <Text  marginTop='5'>Ketentuan ini juga berlaku sebaliknya apabila Anda mengalami tindakan sebagaimana disebutkan di atas yang dilakukan oleh Penyedia 
        Layanan terhadap Anda.</Text>
        <Text  marginTop='5'>14. Pernyataan Anda</Text>
        <Text  marginTop='5'>Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18(delapan belas) tahun atau sudah menikah dan tidak berada dibawah
        perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini. 
        Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses atau menggunakan Aplikasi BRIGHT GAS, Layanan, Konten Pihak 
        Ketiga, Penawaran atau Metode Pembayaran, Anda menyatakan dan menjamin bahwa tindakan Anda membuka,mengakses atau 
        melakukan aktivitas lain dalam Aplikasi BRIGHT GAS telah disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas 
        mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan
        berdasarkan Ketentuan Penggunaan ini pada waktu Anda dianggap telah dewasa secara hukum.</Text>
        <Text  marginTop='5'>Anda setuju untuk mengakses atau menggunakan Aplikasi BRIGHT GAS, Layanan, Konten Pihak Ketiga, Penawaran atau Metode 
        Pembayaran hanya untuk tujuan sebagaimana ditentukan dalam Ketentuan Penggunaan ini dan tidak menyalahgunakan atau menggunakan
        Aplikasi BRIGHT GAS, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran untuk tujuan penipuan, menyebabkan 
        ketidaknyamanan kepada orang lain, melakukan pemesanan palsu atau yang tindakan-tindakan lain yang dapat atau dianggap dapat
        menimbulkan kerugian dalam bentuk apapun terhadap orang lain.</Text>
        <Text  marginTop='5'>Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari penggunaan Aplikasi BRIGHT GAS, Layanan, Konten Pihak Ketiga, 
        Penawaran dan Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda dengan ini setuju untuk melepaskan Kami dari 
        segala tuntutan apapun sehubungan dengan kerusakan, gangguan, atau bentuk lain dari gangguan sistem yang disebabkan oleh akses
        tidak resmi oleh pihak lain.</Text>
        <Text  marginTop='5'>Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas pada, pejabat, direktur, komisaris, karyawan dan agen Kami, dari 
        setiap dan semua kewajiban, konsekuensi, kerugian baik materiil atau immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau 
        tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat pelanggaran Anda terhadap Ketentuan Penggunaan ini maupun 
        sehubungan dengan tindakan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.</Text>
        <Text  marginTop='5'>Dikecualikan dari ketentuan di atas, jika terdapat kerugian langsung akibat pelanggaran Kami terhadap Ketentuan Penggunaan ini, maka 
        Anda setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar jumlah keseluruhan yang senyatanya ditimbulkan atau telah 
        dibayarkan sehubungan dengan peristiwa tersebut.</Text>
        <Text  marginTop='5'>15. Lain-lain</Text>
        <Text  marginTop='5'>Anda mengerti dan setuju bahwa Ketentuan Penggunaan ini merupakan perjanjian dalam bentuk elektronik dan tindakan Anda menekan
        tombol ‘daftar’ saat pembukaan Akun atau tombol ‘masuk’ saat akan mengakses Akun Anda merupakan persetujuan aktif Anda untuk 
        mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Ketentuan Penggunaan ini dan Kebijakan Privasi adalah sah dan 
        mengikat secara hukum dan terus berlaku sepanjang penggunaan Aplikasi BRIGHT GAS dan Layanan oleh Anda.</Text>
        <Text  marginTop='5'>Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Ketentuan Penggunaan atau Kebijakan Privasi yang 
        dibuat dalam bentuk elektronik.</Text>
        <Text  marginTop='5'>Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan Penggunaan ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, 
        Kami dapat mengalihkan hak Kami berdasarkan Ketentuan Penggunaan ini setiap saat kepada pihak lain tanpa perlu mendapatkan 
        persetujuan terlebih dahulu dari atau memberikan pemberitahuan sebelumya kepada Anda.</Text>
        <Text  marginTop='5'>Bila Anda tidak mematuhi atau melanggar ketentuan dalam Ketentuan Penggunaan ini, dan Kami tidak mengambil tindakan secara langsung
        , bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di kemudian hari.</Text>
        <Text  marginTop='5'>Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Aplikasi BRIGHT GAS atau 
        setelah berakhirnya perjanjian ini antara Anda dan Kami.</Text>
        <Text  marginTop='5'>Jika salah satu dari ketentuan dalam Ketentuan Penggunaan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan 
        lainnya.</Text>
        <Text  marginTop='5'>Kami membuat Ketentuan Penggunaan ini dalam bahasa Indonesia dan Inggris. Setiap perbedaan akan diartikan sesuai dengan bahasa 
        Indonesia.</Text>
        <Text  marginTop='5'>Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh perselisihan yang timbul dari penggunaan 
        Aplikasi BRIGHT GAS atau Layanan tunduk pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Selatan.</Text>
        <Text  marginTop='5'>16. Cara Menghubungi Kami</Text>
        <Text  marginTop='5'>Anda dapat menghubungi Kami melalui surat elektronik ke info@BRIGHT GAS.id. Semua korespondensi Anda akan dicatat, direkam dan 
        disimpan untuk arsip Kami.</Text>
        <Text  marginTop='5'>Saya telah membaca dan mengerti seluruh Ketentuan Penggunaan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban
        , dan ketentuan yang diatur di dalamnya.</Text>
        </Box>
        <Box height={61}></Box>
        </Box>
        <Box bg={'#D30E8A'} p={5}>
            <Box experimental_spaceY={2}>
                <Image width={110} height={10} src='/images/gambar-logo.png'/>
                <Text color={'white'} fontFamily={'arial'} fontSize={18} >Belanja Kebutuhan LPJ cukup lewat satu Aplikasi</Text>
            </Box>
            <Flex py={10} experimental_spaceX={20}>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <Text fontWeight={'bold'}>Perusahaan</Text>
                <SimpleGrid>
                    <Link className='link' to='/tentang'>Tentang kami</Link>
                    <Link className='link' to='/layanan'>Layanan</Link>
                </SimpleGrid>  
            </Box>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <SimpleGrid>
                    <Text fontWeight={'bold'}>Bantuan</Text>
                    <Link className='link' to='/bantuan'>Pusat bantuan</Link>
                </SimpleGrid>
            </Box>
            </Flex>
            <Box color={'white'} fontFamily={'arial'} fontSize={15}>
                <Text fontWeight={'bold'}>Kontak</Text>
                <Text>info@brightgasaja.com</Text>
                <Text>(0411) 422961</Text>
                <Text>Jl. A. P. Pettarani No. 18 G,</Text>
                <Text>Tamamaung, Kec. Pannakukang,</Text>
                <Text>Kota Makassar, Sulawesi Selatan</Text>
                <Text>90222</Text>
            </Box>
            <Box py={5} experimental_spaceY={3}>
                <Text color={'white'} fontFamily={'arial'} fontSize={15}>Copyright © 2022 Pt. Massindo Gas Energi</Text>
                <Flex color={'white'} fontFamily={'calibri'} fontSize={17} experimental_spaceX={4}>
                    <Link className='b' to='/ketentuan'>Ketentuan Layanan</Link>
                    <Text color={'white'} fontFamily={'Calibri'} fontSize={15}>|</Text>
                    <Link className='b' to='/kebijakan'>Kebijakan Privasi</Link>
                </Flex>
            </Box>
        </Box>
        </Box>
    )
}

const Ketentuan = () => {
    const [isMobile] = useMediaQuery('(max-width: 1020px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};

export default Ketentuan;