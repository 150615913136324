import Header from "./Header";
import { Text , Image , Button, Box, useMediaQuery, Center, Flex } from '@chakra-ui/react'
import Footer from "./Footer";
import React from "react";

const HomeContentDesktop = () => {
    React.useEffect(() => {
      window.scroll({top: 0, left: 0, behavior: "smooth"})
    },[])
    return (
    <Box>
    <Header/>
    <Box >
    <Flex px={150} py={36} justifyContent={'space-between'}>
    <Center><Box experimental_spaceY={4}>
      <Box fontFamily={'arial'} fontSize={30} color={'white'}>
        <Text>Kami hadir untuk memastikan Anda </Text>
        <Text>senantiasa siap menyajikan hidangan </Text>
        <Text>hangat untuk keluarga tercinta. </Text>
      </Box>
      <Box>
          <Button fontWeight='semibold' fontFamily='arial' fontSize='20px' color='white' width='200px' borderRadius='100px' backgroundColor='#FF99D9'>Unduh Sekarang</Button>
      </Box>
    </Box></Center>
      <Box>
        <Image width={600} height={397} src='/images/gambar-16.png' />
      </Box>
    </Flex>
    <Box bg={'white'} px={150} roundedTop={'3xl'}>
    <Flex experimental_spaceX={20} py={20}>
      <Box>
        <Image width={600} height={380} rounded={'3xl'} src='/images/gambar-17.jpg' />
      </Box>
      <Box experimental_spaceY={3}>
        <Box fontFamily={'arial'} fontSize={28} fontWeight={'bold'} color={'Black'}>
          <Text>Bright Gas Online Order</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={25} color={'Black'}>
          <Text>Kami menyediakan aplikasi untuk memudahkan </Text>
          <Text>Anda melakukan pemesanan gas secara online tanpa ribet. </Text>
        </Box>
      </Box>
    </Flex>
    <Flex py={10} justifyContent={'space-between'}>
      <Box experimental_spaceY={3}>
        <Box fontFamily={'arial'} fontSize={28} fontWeight={'bold'} color={'Black'}>
          <Text>Bright Gas Delivery</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={25} color={'Black'}>
        <Text>Kurir kami senantiasa siap melakukan pengantaran  </Text>
        <Text>dalam kota sehingga Anda tak perlu repot keluar rumah.  </Text>
        </Box>
      </Box>
      <Box>
        <Image width={600} height={380} src='/images/gambar-18.png' />
      </Box>
    </Flex>
    <Flex experimental_spaceX={20} py={20}>
      <Box >
        <Image rounded={'3xl'} width={600} height={380} src='/images/gambar-19.jpg' />
      </Box>
      <Box experimental_spaceY={3}>
        <Box fontFamily={'arial'} fontSize={28} fontWeight={'bold'} color={'Black'}>
          <Text>Bright Gas Accessories</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={25} color={'Black'}>
          <Text>Selain gas, tersedia juga beragam aksesoris dan </Text>
          <Text>produk lainnya untuk memenuhi kebutuhan dapur Anda. </Text>
        </Box>
      </Box>
    </Flex>
    </Box>
    </Box>
    <Footer/>
  </Box>
  )
}

const HomeMobileContent = () => {
  return (
    <Box>
      <Header/>
      <Box py={5} >
        <Box color={'white'} fontFamily={'arial'} fontSize={22} py={5}>
          <Text>Kami hadir untuk memastikan Anda senantiasa siap menyajikan hidangan hangat untuk keluarga tercinta. </Text>
          <Box py={4}>
            <Button bg={'#FF99D9'} fontWeight={'semibold'} fontSize={22} color={'white'} width={200} rounded={'3xl'} >Unduh Sekarang</Button>
          </Box>
          <Box py={5}>
          <Image src="/images/gambar-16.png"/>
          </Box>
        </Box>
        <Box roundedTop={'3xl'} px={5} bg={'white'}>
        <Box py={5} color={'black'} experimental_spaceY={3} fontFamily={'arial'}>
          <Image rounded={'3xl'} src="/images/gambar-17.jpg"/>
          <Text fontSize={20} fontWeight={'bold'}>Bright Gas Online Order</Text>
          <Text fontSize={15}>Kami menyediakan aplikasi untuk memudahkan Anda melakukan pemesanan gas secara online tanpa ribet.</Text>
        </Box>
        <Box py={5} color={'black'} experimental_spaceY={3} fontFamily={'arial'}>
          <Image src="/images/gambar-18.png"/>
          <Text fontSize={20} fontWeight={'bold'}>Bright Gas Delivery</Text>
          <Text fontSize={15}>Kurir kami senantiasa siap melakukan pengantaran dalam kota sehingga Anda tak perlu repot keluar rumah.</Text>
        </Box>
        <Box py={5} color={'black'} experimental_spaceY={3} fontFamily={'arial'}>
          <Image rounded={'3xl'} src="/images/gambar-19.jpg"/>
          <Text fontSize={20} fontWeight={'bold'}>Bright Gas Accessories</Text>
          <Text fontSize={15}>Selain gas, tersedia juga beragam aksesoris dan produk lainnya untuk memenuhi kebutuhan dapur Anda.</Text>
        </Box>
        </Box>
        <Box px={5} mt={10}>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Belum Punya</Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Aplikasi Bright Gas? </Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Dapatkan Sekarang </Text>
        </Box>
        <Box px={5} experimental_spaceY={3} py={14}>
          <Text fontWeight={'bold'} color={'white'}>Unduh Aplikasi</Text>
          <Image width={130} src='/images/gambar-13.png' />
        </Box>
    </Box>
    <Box>
        <Center><Image src='/images/gambar-12.png' width={300}/></Center>
    </Box>
    <Footer/>
    </Box>
  )
}

export default function Layanan(){
    const [isMobile] = useMediaQuery('(max-width: 600px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};