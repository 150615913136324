import Header from "./Header";
import { Image , Text , Input, InputRightElement , InputGroup, Box, useMediaQuery, Center, Flex } from '@chakra-ui/react'
import Footer from "./Footer";
import React from "react";

const HomeContentDesktop = () => {
  React.useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: "smooth"})
  },[])
    return (
    <Box>
    <Header/>
    <Box px={150}>
      <Flex justifyContent={'space-between'} py={12}>
        <Center>
        <Box fontFamily={'arial'} fontSize={48} color={'white'}>
          <Text>Apa yang kami</Text>
          <Text>bisa bantu?</Text>
        </Box>
        </Center>
        <Box>
        <Image width={800} height={550} src='/images/gambar-20.png'/>
        </Box>
      </Flex>
        <Box>
          <InputGroup mt={'-90'}>
          <Input backgroundColor='#FFFFFF' size='md' variant='filled' placeholder="Cari Topik Bantuan"/>
          <InputRightElement children={<svg id="Group_6954" data-name="Group 6954" xmlns="http://www.w3.org/2000/svg" width="17.15" height="21.641" viewBox="0 0 17.15 21.641">
          <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M16.917,18.71,13.577,14.5a.729.729,0,0,0-.569-.3h-.546a10.29,10.29,0,0,0,1.474-5.41C13.936,3.935,10.817,0,6.968,0S0,3.935,0,8.791s3.119,8.791,6.968,8.791a6.089,6.089,0,0,0,4.288-1.86v.689a1.162,1.162,0,0,0,.234.718l3.34,4.214a.69.69,0,0,0,1.136,0l.948-1.2A1.216,1.216,0,0,0,16.917,18.71ZM6.968,14.2C4.6,14.2,2.68,11.783,2.68,8.791S4.6,3.381,6.968,3.381s4.288,2.417,4.288,5.41S9.34,14.2,6.968,14.2Z" fill="#b7b7b7"/>
          </svg>}/>
          </InputGroup>
        </Box>
    </Box>
    <Footer/>
    </Box>
  )
}

const HomeMobileContent = () => {
  return (
    <Box>
      <Header/>
      <Box py={5} px={5}>
        <Box color={'white'} fontSize={40} fontWeight={'semibold'}>
          <Text>Ada yang Kami Bisa Bantu?</Text>
        </Box>
        <Box py={16}>
          <Image  src="/images/gambar-20.png"/>
          <InputGroup pos={'absolute'} w={'300px'} mt={"-70px"}>
          <Input bg={'#DBDBDB'} htmlSize='140' size='lg' variant='filled' placeholder="Cari Topik Bantuan"/>
          <InputRightElement children={<Image width='6' src={'/images/gambar-21.png'}/>}/>
          </InputGroup>
        </Box>
        <Box>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Belum Punya</Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Aplikasi Bright Gas? </Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Dapatkan Sekarang </Text>
        </Box>
        <Box experimental_spaceY={3} py={14}>
            <Text fontWeight={'bold'} color={'white'}>Unduh Aplikasi</Text>
            <Image width={130} src='/images/gambar-13.png' />
          </Box>
        </Box>
        <Box>
          <Center><Image src='/images/gambar-12.png' width={300}/></Center>
        </Box>
        <Footer/>
        </Box>
  )
}

export default function Bantuan(){
    const [isMobile] = useMediaQuery('(max-width: 600px)')

    if(isMobile) return <HomeMobileContent/>

    else return <HomeContentDesktop/>
};