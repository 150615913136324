import React from 'react';
import Header from './Header';
import { Text , Button , Image, Box, Flex, SimpleGrid, Center , useMediaQuery} from '@chakra-ui/react'
import Footer from './Footer';

const Cards = ({title, deskripsi, image}) => (
  <Box bg={'#E75CB4'} experimental_spaceY={10} width={'600px'} height={'300px'} rounded={'3xl'} py={11} px={5} color={'white'}>
    <Flex p={5} >
      <Image width={117} height={114} src={image}/>
      <SimpleGrid px={5} column={2} experimental_spaceY={2}>
        <Text fontWeight={'bold'} fontSize={'2xl'}>{title}</Text>
        <Text>{deskripsi}</Text>
      </SimpleGrid>
      </Flex>
        
      
  </Box>
)

const HomeContentDesktop = () => {
  return (
    <Box>
    <Header/>
    <Box>
    <Flex px={150} py={14} justifyContent={'space-between'}>
      <Center><Box color={'white'} fontWeight={'bold'} fontSize={40}>
        <Text>Keluarga cerdas pasti pilih Bright Gas. </Text>
        <Text>Gunakan aplikasi Bright Gas untuk </Text>
        <Text>melakukan pemesanan online.</Text>
        <Text>Mudah, Cepat, Hemat.</Text>
      <Box>
        <Button fontWeight='semibold' fontFamily='arial' fontSize='20px' color='white' width='200px' borderRadius='100px' backgroundColor='#FF99D9'>Unduh Sekarang</Button>
      </Box>
      </Box></Center>
      <Box>
      <Image width={270} height={573} src='/images/gambar-0.png'/>
      </Box>
    </Flex>
    <Box bg={'white'} px={150} roundedTop={'3xl'}>
    <Flex py={20} experimental_spaceX={20}>
      <Box>
        <Image rounded={'3xl'} width={600} height={380} src='/images/gambar-9.webp' />
      </Box>
      <Box experimental_spaceY={3}>
        <Box fontFamily={'arial'} fontWeight={'semibold'} fontSize={35} color={'#D30E8A'}>
          <Text>Mudah</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={24} color={'black'}>
          <Text>Aplikasi Bright Gas memiliki tampilan</Text>
          <Text>antar muka yang praktis sehingga</Text>
          <Text>mudah untuk digunakan. </Text>
        </Box>
      </Box>
    </Flex>
    <Flex py={20} justifyContent={"space-between"}>
      <Box experimental_spaceY={3}>
        <Box fontFamily={'arial'} fontWeight={'semibold'} fontSize={35} color={'#D30E8A'}>
          <Text>Cepat</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={24} color={'black'}>
        <Text>Pemesanan dilakukan secara realtime</Text>
        <Text>dan pengantaran dilakukan di hari yang sama</Text>
        <Text>sehingga Anda tidak menunggu lama.</Text>
        
        </Box>
      </Box>
      <Box>
        <Image width={600} height={380} src='/images/gambar-10.png'/>
      </Box>
    </Flex>
    <Flex py={20} experimental_spaceX={20}>
      <Box>
        <Image width={600} height={380} src='/images/gambar-11.png' />
      </Box>
      <Box experimental_spaceY={3}>
        <Box fontFamily={'arial'} fontWeight={'semibold'} fontSize={35} color={'#D30E8A'}>
          <Text>Praktis</Text>
        </Box>
        <Box fontFamily={'arial'} fontSize={24} color={'black'}>
          <Text>Segala kebutuhan LPG, aksesoris, dan</Text>
          <Text>produk lainnya tersedia disini.</Text>
          <Text>Belanja beragam produk dalam sekali </Text>
          <Text>pengantaran pasti lebih hemat.</Text>
        </Box>
      </Box>
    </Flex>
    </Box>
    <Box experimental_spaceY={10}>
      <Center>
        <Box mt={16} fontFamily={'arial'} fontWeight={'bold'} fontSize={40} color={'white'}>
          <Center><Text>Pakai Bright Gas,</Text></Center>
          <Text>Keluarga Aman Dan Hatipun Tenang</Text>
        </Box>
      </Center>
      <Box>
        <Flex px={5} experimental_spaceX={3}>
          <Cards image={'/images/gambar-6.png'} title={'Lebih Aman'} deskripsi={'Bright Gas dilengkapi dengan security seal cap atau penutup tabung yang lebih aman. Penutup tabung tersebut menggunakan teknologi double spindle dan karet pelindung.tabung Bright Gas jadi lebih tahan benturan sehingga lebih aman untuk digunakan.'}/>
          <Cards image={'/images/gambar-7.png'} title={'Lebih Nyaman'} deskripsi={'Kini tersedia aplikasi Brightgasaja sehingga Anda tak perlu repot untuk keluar rumah. Nikmati layanan Pesan Antar Bright Gas secara online sekarang juga.'}/>
          <Cards image={'/images/gambar-8.png'} title={'Lebih Terjangkau'} deskripsi={'Harga isi ulang lebih terjangkau untuk kemasan 5,5 kg dan kemasan kaleng.'}/>
        </Flex>
      </Box>
    </Box>
    </Box>
    <Footer/>
    </Box>
  )
}


const HomeMobileContent = () => {
  return (
    <Box>
      <Header/>
      <Box py={5} >
        <Box px={5}>
        <Text color={'white'} fontFamily='arial' fontSize={'22px'} >Keluarga yang cerdas, sudah seharusnya memilih produk LPG yang aman dan hemat. </Text>
        <Box py={4}><Button bg={'#FF99D9'} fontWeight={'semibold'} fontSize={22} color={'white'} width={200} rounded={'3xl'} >Unduh Sekarang</Button></Box>
        <Center py={5}><Image src='/images/gambar-0.png' height={500}/></Center>
        </Box>
        <Box bg={'white'} px={5} roundedTop={'3xl'}>
        <Box py={10} color={'black'}>
          <Image rounded={'3xl'} src='/images/gambar-9.webp'/>
          <Box py={4} experimental_spaceY={2}>
            <Text fontWeight={'bold'} fontSize={21}>Mudah</Text>
            <Text >Aplikasi Bright Gas memiliki tampilan antar muka yang praktis sehingga mudah untuk digunakan.  </Text>
          </Box>
        </Box>
        <Box color={'black'}>
          <Image src='/images/gambar-10.png'/>
          <Box py={4} experimental_spaceY={2}>
            <Text fontWeight={'bold'} fontSize={21}>Cepat</Text>
            <Text >Pemesanan dilakukan secara realtime dan pengantaran dilakukan di hari yang sama sehingga Anda tidak menunggu lama.</Text>
          </Box>
        </Box>
        <Box py={14} color={'black'}>
          <Image src='/images/gambar-11.png'/>
          <Box py={4} experimental_spaceY={2}>
            <Text fontWeight={'bold'} fontSize={21}>Hemat</Text>
            <Text >Segala kebutuhan LPG, aksesoris, dan produk lainnya tersedia disini. Belanja beragam produk dalam sekali pengantaran pasti lebih hemat.</Text>
          </Box>
        </Box>
        </Box>
        <Box mt={10} px={5}>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Belum Punya</Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Aplikasi Bright Gas? </Text>
          <Text fontSize={'3xl'} color={'white'} fontWeight={'bold'}>Dapatkan Sekarang </Text>
        </Box>
        <Box px={5} experimental_spaceY={3} py={14}>
          <Text fontWeight={'bold'} color={'white'}>Unduh Aplikasi</Text>
          <Image width={130} src='/images/gambar-13.png' />
        </Box>
      </Box>
      <Box>
        <Center><Image src='/images/gambar-12.png' width={300}/></Center>
      </Box>
      <Footer/>
      </Box>
  )
}

const Home = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  if(isMobile) return <HomeMobileContent/>

  else return <HomeContentDesktop/>
};

export default Home;